import React, {useRef} from 'react';
import {toast} from 'react-toastify';
import {Link, useHistory} from 'react-router-dom';
import {useMutation} from 'react-query';
import {Card, Form, Row, Col} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {
    messageError,
    status200,
    status400,
    messageToast,
    typeError
} from '../../utils/define';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {AUTH_API} from '../../utils/apiUrl';
import {Post} from '../../utils/httpProvider';

const RecoverPassword = () => {
    // const passwordInput = useRef(null);
    // const confirmPasswordInput = useRef(null);
    const [t] = useTranslation();
    const history = useHistory();

    document.getElementById('root').classList = 'hold-transition login-page';

    const addMutation = useMutation((value) =>
        Post(AUTH_API.RESET_PASSWORD, null, value)
    );
    const handlePost = async (values, actions) => {
        addMutation.mutate(values, {
            onSuccess: async (respone) => {
                try {
                    if (respone.status === status200) {
                        toast.success(
                            messageToast.messageToastRecoverPassSuccess
                        );
                        history.push(URI_INTERNAL.LOGIN_PATH);
                    }
                    if (respone.status === status400) {
                        const result = await respone.json();
                        if (result.type) {
                            if (result.type === typeError.Email) {
                                actions.setErrors({
                                    email: result.errors
                                        ? result.errors.content[0]
                                        : ''
                                });
                            }
                            if (result.type === typeError.Code) {
                                actions.setErrors({
                                    code: result.errors
                                        ? result.errors.content[0]
                                        : ''
                                });
                            }
                        }
                    }
                } catch (error) {
                    toast.error(messageToast.messageToastTryCatch);
                }
            },
            onError: () => {}
        });
    };

    const schema = yup.object().shape({
        email: yup
            .string()
            .email(messageError.emailMessage)
            .required(messageError.emptyMessage),
        code: yup.string().required(messageError.emptyMessage),
        password: yup
            .string()
            .min(6, messageError.minLength6Message)
            .max(30, messageError.maxLength30Message)
            .required(messageError.emptyMessage),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], messageError.likePassword)
            .required(messageError.emptyMessage)
    });

    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <b>Admin</b>
                        <span>LTE</span>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">
                        Bạn chỉ còn 1 bước nữa để lấy lại mật khẩu, hãy tiếp tục
                    </p>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, actions) =>
                            handlePost(values, actions)
                        }
                        initialValues={{
                            email: 'sting',
                            code: '',
                            password: '',
                            confirmPassword: ''
                        }}
                    >
                        {({handleSubmit, handleChange, values, errors}) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="input-group mb-3">
                                    <Form.Control
                                        placeholder="Email"
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                    {/* <input
                                        ref={passwordInput}
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="input-group mb-3">
                                    <Form.Control
                                        placeholder="Mã xác thực"
                                        type="text"
                                        name="code"
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.code}
                                    </Form.Control.Feedback>
                                </div>
                                <div className="input-group mb-3">
                                    <Form.Control
                                        type="password"
                                        placeholder="Mật khẩu"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={!!errors.password}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </div>
                                <div className="input-group mb-3">
                                    <Form.Control
                                        type="password"
                                        placeholder="Xác nhận mật khẩu"
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.confirmPassword}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                        >
                                            Xác nhận
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <p className="mt-3 mb-1">
                        <Link to={URI_INTERNAL.LOGIN_PATH}>
                            {t('login.button.signIn.label')}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RecoverPassword;
