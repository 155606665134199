import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { logPagingAction, filterListLog } from '@store/reducers/log';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { Form, Row, Col } from 'react-bootstrap';
import { LOG_API } from '@app/utils/apiUrl';
import { Post, Get } from '@app/utils/httpProvider';
import DatePicker from 'react-datepicker';
import {
    createUrlWithParam,
    formatDateTimeWithoutHourTypeOneMoment,
    formatDateTimeCrawler
} from '@app/utils/helper';
import { URI_INTERNAL } from '@app/utils/pathLocations';
import { objectShowNumber, messageToast, rangeDate } from '@app/utils/define';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as moment from 'moment';
import { ConfirmModal } from '@app/modules/modal/ConfirmModal';

const Log = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const valueSearch = useSelector((state) => state.search.valueSearch);
    // const totalLog = useSelector((state) => state.log.totalLog);
    // const listLog = useSelector((state) => state.log.listLog);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [confirmDelMessage, setConfirmDelMessage] = useState('');

    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDeleteDay, endDeleteDay] = dateRange;

    const timeCurentDay = moment().subtract(6, 'days');
    timeCurentDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const [dates, setDates] = useState({
        startDate: timeCurentDay,
        endDate: moment()
    });

    const deleteFunc = useMutation((value) =>
        Post(LOG_API.DELETE_LOG, null, value, false)
    );

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['newsCrawlerData', valueSearch, page, pageSize],
        () =>
            Get(LOG_API.GET_PAGING_LOG, null, {
                Keyword: valueSearch ? valueSearch : '',
                PageIndex: page,
                PageSize: pageSize
            })
    );

    useEffect(() => {
        refetch();
    }, [pageSize, page]);

    const replaceHistory = async (Page, Size, Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.LOG_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch);
        }
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    const deleteAction = (startDelDay, endDelDay) => {
        if (startDelDay == null) {
            toast.error(messageToast.messageToastChooseDateError);
        } else {
            if (endDelDay == null) {
                endDelDay = new Date();
            }
            setConfirmDelMessage(
                `từ ngày ${formatDateTimeWithoutHourTypeOneMoment(
                    startDelDay
                )} đến ngày ${formatDateTimeWithoutHourTypeOneMoment(
                    endDelDay
                )}`
            );
            setOpenConfirmDeleteModal(true);
        }
    };

    const acceptDelete = async () => {
        const startDate = dates.startDate.toDate();
        const endDate = dates.endDate.toDate();
        startDate.setHours(startDate.getHours() + 7);
        endDate.setHours(endDate.getHours() + 7);

        const modelDelete = {
            startDay: startDate,
            endDay: endDate
        };

        deleteFunc.mutate(modelDelete, {
            onSuccess: (res) => {
                // startDeleteDay.setHours(startDeleteDay.getHours() - 7);
                // endDeleteDay.setHours(endDeleteDay.getHours() - 7);
                if (res.status === 200) {
                    // dispatch(filterListLog(modelDelete));
                    refetch();
                    toast.success(messageToast.messageToastDeleteSuccess);
                    // history.goBack();
                } else {
                    toast.error(messageToast.messageToastDeleteError);
                }
            },
            onError: (res) => {
                toast.error(messageToast.messageToastTryCatch);
            }
        });

        setOpenConfirmDeleteModal(false);
    };

    const cancelDelete = () => {
        setOpenConfirmDeleteModal(false);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách lỗi</h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Hiển thị :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </form>
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Chọn ngày để xóa:
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate,
                                            startDate: moment(
                                                dates.startDate
                                            ).format('MM/DD/YYYY'),
                                            endDate: moment(
                                                dates.endDate
                                            ).format('MM/DD/YYYY')
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            defaultValue={`${moment(
                                                dates.startDate
                                            ).format('DD/MM/YYYY')} - ${moment(
                                                dates.endDate
                                            ).format('DD/MM/YYYY')}`}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                    <NavLink
                                        onClick={() =>
                                            deleteAction(
                                                dates.startDate,
                                                dates.endDate
                                            )
                                        }
                                        to
                                        exact
                                        className="nav-link"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            Xóa
                                        </button>
                                    </NavLink>
                                </form>

                                {/* <Form inline>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label className="col-sm-2 col-form-label">
                                            Chọn ngày:
                                        </Form.Label>
                                        <Col sm="10" className="icheck-primary">
                                            <DatePicker
                                                selectsRange="true"
                                                startDate={startDeleteDay}
                                                endDate={endDeleteDay}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                isClearable="true"
                                                dateFormat={
                                                    DATE_TIME_REACT_WITHOUT_HOUR_FORMAT
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Form> */}
                            </div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th>Log cấp</th>
                                                        <th>Thông điệp</th>
                                                        <th>Ngày tạo</th>
                                                    </tr>
                                                </thead>
                                                {data.data.$values != null &&
                                                    data.data.$values !==
                                                    undefined &&
                                                    data.data.$values.length > 0 ? (
                                                    <tbody>
                                                        {data.data.$values.map(
                                                            (item, index) => (
                                                                <tr
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="odd"
                                                                >
                                                                    <td className="dtr-control sorting_1">
                                                                        {index +
                                                                            1 +
                                                                            (page -
                                                                                1) *
                                                                            pageSize}
                                                                    </td>
                                                                    <td
                                                                        className="sorting_1"
                                                                        title={`${item.parameter
                                                                                ? item.parameter
                                                                                : ''
                                                                            }  message: ${item.message
                                                                            }`}
                                                                    >
                                                                        {
                                                                            item.logLevel
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.message
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {formatDateTimeCrawler(
                                                                            item.createdDate
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th>Log cấp</th>
                                                        <th>Thông điệp</th>
                                                        <th>Ngày tạo</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="example2_paginate"
                                            >
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination pagination-sm no-margin pull-right"
                                                    activePage={page}
                                                    itemsCountPerPage={Number(
                                                        pageSize
                                                    )}
                                                    totalItemsCount={
                                                        data.totalRecords
                                                    }
                                                    pageRangeDisplayed={5}
                                                    onChange={(number) =>
                                                        changePage(number)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Log;
