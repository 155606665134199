import React, {useState} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Get, Post} from '@app/utils/httpProvider';
import {useQuery, useMutation} from 'react-query';
import Editor from '@app/components/editor/Editor';
import SelectTree from '@app/components/select-tree/SelectTree';
import {
    messageError,
    messageToast,
    postStatus,
    status200,
} from '../../utils/define';
import {NEWSCRAWLER_API, POST_API, CATEGORY_API} from '../../utils/apiUrl';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    categoryId: yup.string().required(messageError.emptyMessage)
});

const NewsCrawlerEdit = () => {
    const history = useHistory();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));

    const newsCrawlerItem = useQuery(['newsCrawlerItem'], () =>
        Get(NEWSCRAWLER_API.GET_ITEM_NEWSCRAWLER, paramUrl.get('id'))
    );

    const listCate = useQuery(['cateData'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const addFunc = useMutation((value) =>
        Post(POST_API.ADD_POST, null, value, false)
    );

    const handleEdit = async (values) => {
        const model = {...values};
        model.status = postStatus.draft;
        model.dataType = listCate.data.data.$values.find(
            (x) => x.id === values.categoryId
        ).dataType;
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        // model.publishTime = new Date();

        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const isLoading = newsCrawlerItem.isLoading || listCate.isLoading;
    const isError = newsCrawlerItem.isError || listCate.isError;
    const error = newsCrawlerItem.isError
        ? newsCrawlerItem.error
        : listCate.error;

    const listMenu = listCate.data ? listCate.data.data.$values : [];

    // let listMenu = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];

    // listMenu = listMenu.concat(listCate.data ? listCate.data.data.$values : []);

    return (
        <Card>
            <Card.Header as="h5">Sửa tin lấy về </Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : newsCrawlerItem.data == null ? (
                    <div>Tin tức không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            baseUrl:
                                newsCrawlerItem.data &&
                                newsCrawlerItem.data.baseUrl
                                    ? newsCrawlerItem.data.baseUrl
                                    : '',
                            sourceLink:
                                newsCrawlerItem.data && newsCrawlerItem.data.url
                                    ? newsCrawlerItem.data.url
                                    : '',
                            title:
                                newsCrawlerItem.data &&
                                newsCrawlerItem.data.title
                                    ? newsCrawlerItem.data.title
                                    : '',
                            category:
                                newsCrawlerItem.data &&
                                newsCrawlerItem.data.category
                                    ? newsCrawlerItem.data.category
                                    : '',
                            description:
                                newsCrawlerItem.data &&
                                newsCrawlerItem.data.description
                                    ? newsCrawlerItem.data.description
                                    : '',
                            content:
                                newsCrawlerItem.data &&
                                newsCrawlerItem.data.content
                                    ? newsCrawlerItem.data.content
                                    : '',
                            fromWhichSource:
                                newsCrawlerItem.data &&
                                newsCrawlerItem.data.fromWhichSource
                                    ? newsCrawlerItem.data.fromWhichSource
                                    : ''
                            // postStatus: ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Đường link nguồn
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Đường link nguồn"
                                            type="text"
                                            name="baseUrl"
                                            value={values.baseUrl}
                                            onChange={handleChange}
                                            isInvalid={!!errors.baseUrl}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.baseUrl}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Đường link
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Link"
                                            name="url"
                                            value={values.sourceLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.sourceLink}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.sourceLink}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Tiêu đề"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={listMenu}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={values.id}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Phân loại
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Phân loại"
                                            name="category"
                                            value={values.category}
                                            onChange={handleChange}
                                            isInvalid={!!errors.category}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.category}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="content"
                                            value={values.content}
                                            onChange={(value) => {
                                                setFieldValue('content', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ nguồn
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Từ nguồn"
                                            name="fromWhichSource"
                                            value={values.fromWhichSource}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fromWhichSource}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.fromWhichSource}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectStatusPost}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Tạo mới bài viết
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingEdit} />
            </Card.Body>
        </Card>
    );
};

export default NewsCrawlerEdit;
