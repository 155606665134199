import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Get } from '@app/utils/httpProvider';
import { WEB_ANALYSIS } from '@app/utils/apiUrl';
import ReactExport from 'react-export-excel';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { rangeDate } from '@app/utils/define';
import {
    getDateTimeISOString,
    exportExcel
} from '../../utils/helper';

const ReportPostByWebAnalysis = () => {
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['reportPostByWebAnalysisData'],
        () =>
            Get(WEB_ANALYSIS.COUNT_VISITS, null, {
                fromDate: dates.startDate ? getDateTimeISOString(dates.startDate.toDate()) : '',
                toDate: dates.endDate ? getDateTimeISOString(dates.endDate.toDate()) : ''
            })
    );

    React.useEffect(() => {
        refetch();
    }, [dates]);

    const filterColumns = (data) => {
        const columns = {};
        data.forEach((column, index) => {
            columns[column.title] = column.getCount;
        });

        return [columns];
    };

    const getDict = (data) => {
        const dict = [];
        data.forEach((item, index) => {
            const itemDict = {};
            itemDict.label = item.title;
            dict.push(itemDict);
        });
        return dict;
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {' '}
                                Báo cáo thống kê truy cập tin bài
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between mb-2">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Chọn ngày :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                </form>

                                <Form inline>
                                    <NavLink
                                        onClick={() => {
                                            refetch();
                                        }}
                                        to
                                        exact
                                        className="nav-link"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            Xem thống kê
                                        </button>
                                    </NavLink>

                                    <NavLink
                                        className="nav-link-reject nav-link"
                                        to
                                        onClick={() => exportExcel(`baocao_theotruycap_${Date.now()}`)}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            Xuất file Excel
                                        </button>
                                    </NavLink>

                                    {/* <ReactExport.ExcelFile
                                        element={
                                            <NavLink
                                                className="nav-link-reject nav-link"
                                                to
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                >
                                                    Xuất Excel
                                                </button>
                                            </NavLink>
                                        }
                                        filename="baocao"
                                    >
                                        <ReactExport.ExcelFile.ExcelSheet
                                            data={
                                                data != null &&
                                                    data !== undefined
                                                    ? filterColumns(
                                                        data.$values
                                                    )
                                                    : []
                                            }
                                            name={`baocao_theotruycap_${Date.now()}`}
                                        >
                                            {getDict(
                                                data != null &&
                                                    data !== undefined
                                                    ? data.$values
                                                    : []
                                            ).map((key, index) => (
                                                <ReactExport.ExcelFile.ExcelColumn
                                                    label={
                                                        getDict(data.$values)[
                                                            index
                                                        ].label
                                                    }
                                                    value={
                                                        getDict(data.$values)[
                                                            index
                                                        ].label
                                                    }
                                                />
                                            ))}
                                        </ReactExport.ExcelFile.ExcelSheet>
                                    </ReactExport.ExcelFile> */}
                                </Form>
                            </div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table id="table-to-xls" striped bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        {data != null &&
                                                            data !== undefined &&
                                                            data.$values != null &&
                                                            data.$values !==
                                                            undefined &&
                                                            data.$values.length >
                                                            0 ? (
                                                            data.$values.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <th className="sorting">
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </th>
                                                                )
                                                            )
                                                        ) : (
                                                            <th className="sorting">
                                                                Tiêu đề
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="dtr-control sorting_1">
                                                            1
                                                        </td>
                                                        {data != null &&
                                                            data !== undefined &&
                                                            data.$values != null &&
                                                            data.$values !==
                                                            undefined &&
                                                            data.$values.length >
                                                            0 ? (
                                                            data.$values.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <td className="sorting">
                                                                        {
                                                                            item.getCount
                                                                        }
                                                                    </td>
                                                                )
                                                            )
                                                        ) : (
                                                            <td className="sorting">
                                                                Đếm số lượng
                                                            </td>
                                                        )}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPostByWebAnalysis;
