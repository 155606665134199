import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ChangePassWordTab from './ChangePassWordTab';
import {getInfomationAccountFromToken} from '../../utils/helper';
import SettingsTab from './SettingsTab';

import {getByIdAccountAction} from '../../store/reducers/account';

const Profile = () => {
    const [activeTab, setActiveTab] = useState('SETTINGS');
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const itemAccount = useSelector((state) => state.account.itemAccount);

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        const accountItem = getInfomationAccountFromToken();
        if (accountItem) {
            dispatch(getByIdAccountAction(accountItem.id));
        }
    }, []);

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Tài khoản</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">{t('header.label.home')}</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    {t('views.user.profile')}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card card-primary card-outline">
                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        <img
                                            className="profile-user-img img-fluid img-circle"
                                            src="/img/default-profile.png"
                                            alt="User profile"
                                        />
                                    </div>
                                    <h3 className="profile-username text-center">
                                        {itemAccount
                                            ? itemAccount.username
                                            : ''}
                                    </h3>
                                    <p className="text-muted text-center">
                                        {itemAccount
                                            ? itemAccount.fullName
                                            : ''}
                                    </p>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        <li className="list-group-item">
                                            <b>Tài khoản</b>

                                            <span className="float-right">
                                                {itemAccount
                                                    ? itemAccount.username
                                                    : ''}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Họ tên</b>
                                            <span className="float-right">
                                                {itemAccount
                                                    ? itemAccount.fullName
                                                    : ''}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Email</b>
                                            <span className="float-right">
                                                {itemAccount
                                                    ? itemAccount.email
                                                    : ''}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header p-2">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${
                                                    activeTab === 'SETTINGS'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    toggle('SETTINGS')
                                                }
                                            >
                                                Cài đặt tài khoản
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${
                                                    activeTab ===
                                                    'CHANGEPASSWORD'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    toggle('CHANGEPASSWORD')
                                                }
                                            >
                                                Đổi mật khẩu
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                               
                                <div className="card-body">
                                    <div className="tab-content">
                                        <SettingsTab
                                            isActive={activeTab === 'SETTINGS'}
                                        />
                                        <ChangePassWordTab
                                            isActive={
                                                activeTab === 'CHANGEPASSWORD'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Profile;
