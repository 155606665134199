import React, { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { Card } from 'react-bootstrap';
import * as moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import SmallBox from '../components/small-box/SmallBox';
import { POST_API, ACCOUNT_API, WEB_ANALYSIS, LOG_API, LOGIN_HISTORY_API, ADVERTISING_ANALYSIS_API } from '../utils/apiUrl';
import { Get } from '../utils/httpProvider';
import ListPost from './post/ListPost';
import {
    postStatus,
    reportPost,
    objectStatusPost,
    rangeDate
} from '../utils/define';
import { getDateTimeISOString, dateTimeToStringRequest } from '../utils/helper';

const Dashboard = () => {
    const refBaocao = useRef(null);
    const [activeTab, setActiveTab] = useState(postStatus.all);
    const [chieucao, setChieucao] = useState(0);

    const timeCurentDay = moment().subtract(6, 'days');
    timeCurentDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const [dates, setDates] = useState({
        startDate: timeCurentDay,
        endDate: moment()
    });

    // Thống kê bài viết theo ngày tạo
    const lstCoutPostByCreateDate = useQuery(['getCoutPostByCreateDate'], () =>
        Get(POST_API.COUNT_POST_BY_CONDITION_API, null, {
            fromDate: getDateTimeISOString(dates.startDate.toDate()),
            toDate: getDateTimeISOString(dates.endDate.toDate()),
            ReportType: reportPost.ByCreatedDate
        })
    );

    // dữ liệu cho biểu đồ thống kê bài viết theo ngày tạo
    let labelsPostChart = [];
    let dataPostChart = [];
    if (lstCoutPostByCreateDate && lstCoutPostByCreateDate.data) {
        if (lstCoutPostByCreateDate.data.$values.length > 0) {
            lstCoutPostByCreateDate.data.$values.forEach((item) => {
                labelsPostChart = labelsPostChart.concat(
                    moment(item.title).format('DD/MM/YYYY')
                );
                dataPostChart = dataPostChart.concat(item.total);
            });
        }
    }

    useEffect(() => {
        lstCoutPostByCreateDate.refetch();
    }, [dates]);

    // Lấy tổng số thành viên
    const countAccount = useQuery(['getAccount'], () =>
        Get(ACCOUNT_API.COUNT_ALL_ACCOUNT_API)
    );

    // Lấy tất cả post status từ trước đến nay
    const lstStatisticPostByStatus = useQuery(
        ['getStatisticPostByStatus'],
        () =>
            Get(POST_API.COUNT_POST_BY_CONDITION_API, null, {
                ReportType: reportPost.ByStatus
            })
    );
    const fromDate7 = getDateTimeISOString(
        moment()
            .subtract(6, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate()
    );
    const toDateNow = getDateTimeISOString(moment().toDate());

    // Thống kê bài viết theo data type tin tức trong ngày
    const lstCoutPostByDataTypeInformationByDay = useQuery(['lstCoutPostByDataTypeInformationByDay'], () =>
        Get(POST_API.COUNT_POST_BY_CONDITION_API, null, {
            fromDate: toDateNow,
            toDate: toDateNow,
            ReportType: reportPost.DataType
        })
    );

    // Thống kê bài viết theo data type trong bảy ngày
    const lstCoutPostByDataTypeInformationBySevenDay = useQuery(['getCoutPostByDataTypeBySevenDay'], () =>
        Get(POST_API.COUNT_POST_BY_CONDITION_API, null, {
            fromDate: fromDate7,
            toDate: toDateNow,
            ReportType: reportPost.DataType
        })
    );

    // thống kê thông báo trong 7 ngày
    const lstLogByDate7 = useQuery(['logByDate7'], () =>
        Get(LOG_API.COUNT_LOG_BY_DATE, null, {
            fromDate: fromDate7,
            toDate: toDateNow
        })
    );
    // thống kê thông báo từ trước đến nay
    const lstLogByDate = useQuery(['logByDate'], () =>
        Get(LOG_API.COUNT_LOG_BY_DATE, null, null)
    );

    // thống kê tổng lượt xem bài viết hôm nay và 7 ngày gần đây
    const countTotalByTodayAndSevenDay = useQuery(['countTotalByTodayAndSevenDay'], () => {
        const model = {};
        model.fromDate = fromDate7;
        model.toDate = toDateNow;
        return Get(WEB_ANALYSIS.COUNT_VISITS_BY_DATE, null, model);
    }
    );

    // thống kê tổng lượt truy cập hôm nay và 7 ngày gần đây
    const countTotalLoginByTodayAndSevenDays = useQuery(['countTotalLoginByTodayAndSevenDays'], () => {
        const model = {};
        model.fromDate = fromDate7;
        model.toDate = toDateNow;
        return Get(LOGIN_HISTORY_API.STATISTIC_COUNT_LOGIN_BY_DATE, null, model);
    }
    );

    // thống kê tổng lượt click quảng cáo hôm nay và 7 ngày gần đây
    const countTotalClickAdvertisingByTodayAndSevenDays = useQuery(['countTotalClickAdvertisingByTodayAndSevenDays'], () => {
        const model = {};
        model.fromDate = fromDate7;
        model.toDate = toDateNow;
        return Get(ADVERTISING_ANALYSIS_API.COUNT_ADVERTISING_ANALYSIS_BY_DATE, null, model);
    }
    );

    // thống kê truy cập bài viết trong 7 ngày
    const lstWebAnalyByDate = useQuery(['webAnalyByDate'], () =>
        Get(WEB_ANALYSIS.COUNT_WEB_ANALYSIS_BY_DATE, null, {
            fromDate: fromDate7,
            toDate: toDateNow
        })
    );

    // dữ liệu cho biểu đồ thống kê truy cập bài viết
    let labelsWebAnalyChart = [];
    let dataWebAnalyChart = [];
    if (lstWebAnalyByDate && lstWebAnalyByDate.data) {
        if (lstWebAnalyByDate.data.$values.length > 0) {
            lstWebAnalyByDate.data.$values.forEach((item) => {
                labelsWebAnalyChart = labelsWebAnalyChart.concat(
                    moment(item.title).format('DD/MM/YYYY')
                );
                dataWebAnalyChart = dataWebAnalyChart.concat(item.total);
            });
        }
    }

    // Lấy tổng số thống kê post status trong 7 ngày
    const modelPostDate = {
        fromDate: fromDate7,
        toDate: toDateNow,
        ReportType: reportPost.ByStatus
    };
    const lstStatisticPostByStatusFromDate = useQuery(
        ['getStatisticPostByStatusFromDate'],
        () => Get(POST_API.COUNT_POST_BY_CONDITION_API, null, modelPostDate)
    );

    const objectStatusShow = [
        {
            title: 'Bài viết đang biên tập',
            status: postStatus.draft,
            type: 'FFC890',
            count:
                lstStatisticPostByStatus &&
                    lstStatisticPostByStatus.data &&
                    lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.draft
                    )
                    ? lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.draft
                    ).total
                    : 0,
            count7date:
                lstStatisticPostByStatusFromDate &&
                    lstStatisticPostByStatusFromDate.data &&
                    lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.draft
                    )
                    ? lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.draft
                    ).total
                    : 0
        },
        {
            title: 'Bài viết chờ duyệt',
            status: postStatus.pending,
            type: '596F90',
            count:
                lstStatisticPostByStatus &&
                    lstStatisticPostByStatus.data &&
                    lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.pending
                    )
                    ? lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.pending
                    ).total
                    : 0,
            count7date:
                lstStatisticPostByStatusFromDate &&
                    lstStatisticPostByStatusFromDate.data &&
                    lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.pending
                    )
                    ? lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.pending
                    ).total
                    : 0
        },
        {
            title: 'Bài đã duyệt',
            status: postStatus.release,
            type: 'FF5959',
            count:
                lstStatisticPostByStatus &&
                    lstStatisticPostByStatus.data &&
                    lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.release
                    )
                    ? lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.release
                    ).total
                    : 0,
            count7date:
                lstStatisticPostByStatusFromDate &&
                    lstStatisticPostByStatusFromDate.data &&
                    lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.release
                    )
                    ? lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.release
                    ).total
                    : 0
        },
        {
            title: 'Bài trả lại',
            status: postStatus.refuse,
            type: 'E9C8B2',
            count:
                lstStatisticPostByStatus &&
                    lstStatisticPostByStatus.data &&
                    lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.refuse
                    )
                    ? lstStatisticPostByStatus.data.$values.find(
                        (x) => x.title === postStatus.refuse
                    ).total
                    : 0,
            count7date:
                lstStatisticPostByStatusFromDate &&
                    lstStatisticPostByStatusFromDate.data &&
                    lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.refuse
                    )
                    ? lstStatisticPostByStatusFromDate.data.$values.find(
                        (x) => x.title === postStatus.refuse
                    ).total
                    : 0
        }
    ];
    // tổng số bài viết
    let countAllPost = 0;
    objectStatusShow.forEach(function (element) {
        countAllPost += element.count;
    });

    // tổng số bài viết trong 7 ngày qua
    let countAllPost7 = 0;
    objectStatusShow.forEach(function (element) {
        countAllPost7 += element.count7date;
    });

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        setChieucao(refBaocao.current.clientHeight);
    });

    const resizeWindow = () => {
        setChieucao(refBaocao.current.clientHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', resizeWindow);
        return () => window.removeEventListener('resize', resizeWindow);
    }, []);

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };
    return (
        <div className="container-fluid">
            <div className="row">
                {objectStatusShow.map((item, index) => (
                    <div key={Number(index)} className="col-lg-3 col-6">
                        <SmallBox
                            count={item.count}
                            title={item.title}
                            type={item.type}
                            des={`${item.count7date} bài viết trong 7 ngày gần đây`}
                            navigateTo="/"
                        />
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={countAllPost}
                        title="Tổng số bài viết"
                        type="59C859"
                        des={`${countAllPost7} bài viết trong 7 ngày gần đây`}
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={countTotalByTodayAndSevenDay && countTotalByTodayAndSevenDay.data ? countTotalByTodayAndSevenDay.data.getCountTotal : 0}
                        title="Tổng lượt xem bài viết"
                        type="59C8C8"
                        icon="ion-android-people"
                        des="Số lượng lượt xem bài viết"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={countTotalByTodayAndSevenDay && countTotalByTodayAndSevenDay.data ? countTotalByTodayAndSevenDay.data.getCountToday : 0}
                        title="Lượt xem bài viết trong ngày"
                        type="8D8CCC"
                        des={`${countTotalByTodayAndSevenDay && countTotalByTodayAndSevenDay.data ? countTotalByTodayAndSevenDay.data.getCountSevenDay : 0} lượt xem bài viết trong 7 ngày gần đây`}
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={countTotalLoginByTodayAndSevenDays && countTotalLoginByTodayAndSevenDays.data && countTotalLoginByTodayAndSevenDays.data.getCountToday ? countTotalLoginByTodayAndSevenDays.data.getCountToday : 0}
                        title="Lượt truy cập trong ngày"
                        type="85D385"
                        des={`${countTotalLoginByTodayAndSevenDays && countTotalLoginByTodayAndSevenDays.data ? countTotalLoginByTodayAndSevenDays.data.getCountSevenDay : 0} lượt truy cập trong 7 ngày gần đây`}
                        navigateTo="/"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={lstLogByDate && lstLogByDate.data ? lstLogByDate.data.total : 0}
                        title="Thông báo"
                        type="945A7C"
                        icon="ion-android-people"
                        des={`${lstLogByDate7 && lstLogByDate7.data ? lstLogByDate7.data.total : 0} thông báo trong 7 ngày gần đây`}
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={
                            countAccount && countAccount.data
                                ? countAccount.data.total
                                : 0
                        }
                        title="Thành viên"
                        type="9F96BB"
                        des="Các thành viên quản trị nội dung web "
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={lstCoutPostByDataTypeInformationByDay && lstCoutPostByDataTypeInformationByDay.data && lstCoutPostByDataTypeInformationByDay.data.$values.length > 0 ? lstCoutPostByDataTypeInformationByDay.data.$values[0].total : 0}
                        title="Số bài loại tin tức trong ngày"
                        type="C85959"
                        des={`${lstCoutPostByDataTypeInformationBySevenDay && lstCoutPostByDataTypeInformationBySevenDay.data && lstCoutPostByDataTypeInformationBySevenDay.data.$values.length > 0 ? lstCoutPostByDataTypeInformationBySevenDay.data.$values[0].total : 0} bài loại tin tức trong 7 ngày gần đây`}
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={countTotalClickAdvertisingByTodayAndSevenDays && countTotalClickAdvertisingByTodayAndSevenDays.data && countTotalClickAdvertisingByTodayAndSevenDays.data ? countTotalClickAdvertisingByTodayAndSevenDays.data.getCountToday : 0}
                        title="Lượt truy cập quảng cáo trong ngày"
                        type="008A84"
                        des={`${countTotalClickAdvertisingByTodayAndSevenDays && countTotalClickAdvertisingByTodayAndSevenDays.data ? countTotalClickAdvertisingByTodayAndSevenDays.data.getCountSevenDay : 0} lượt truy cập quảng cáo trong 7 ngày gần đây`}
                        navigateTo="/"
                    />
                </div>
            </div>
            <div style={{ marginBottom: '1rem' }} className="row">
                <div style={{ height: chieucao }} className="col-lg-6 col-6">
                    <Card style={{ height: '100%' }}>
                        <Card.Header style={{ fontSize: 25 }}>
                            Nội dung bài viết
                        </Card.Header>
                        <Card.Body>
                            <div className="col-md-12" style={{ height: '100%' }}>
                                <div
                                    className="card-header p-2"
                                    style={{ height: '100%' }}
                                >
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${activeTab === postStatus.all
                                                    ? 'active'
                                                    : ''
                                                    }`}
                                                onClick={() =>
                                                    toggle(postStatus.all)
                                                }
                                            >
                                                Tất cả
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${activeTab ===
                                                    postStatus.pending
                                                    ? 'active'
                                                    : ''
                                                    }`}
                                                onClick={() =>
                                                    toggle(postStatus.pending)
                                                }
                                            >
                                                Chờ duyệt
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${activeTab ===
                                                    postStatus.release
                                                    ? 'active'
                                                    : ''
                                                    }`}
                                                onClick={() =>
                                                    toggle(postStatus.release)
                                                }
                                            >
                                                Đã duyệt
                                            </button>
                                        </li>
                                    </ul>
                                    <div
                                        className="tab-content"
                                        style={{
                                            height: '100%',
                                            paddingBottom: 50
                                        }}
                                    >
                                        <ListPost status={activeTab} />
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div
                    id="baocaothongke"
                    ref={refBaocao}
                    className="col-lg-6 col-6"
                >
                    <Card style={{ height: '100%' }}>
                        <Card.Header style={{ fontSize: 25 }}>
                            Thống kê bài viết
                        </Card.Header>
                        <Card.Header>
                            <DateRangePicker
                                onApply={handleApply}
                                onCancel={handleCancel}
                                initialSettings={{
                                    autoUpdateInput: false,
                                    locale: {
                                        cancelLabel: 'Clear'
                                    },
                                    ranges: rangeDate,
                                    startDate: moment(dates.startDate).format(
                                        'MM/DD/YYYY'
                                    ),
                                    endDate: moment(dates.endDate).format(
                                        'MM/DD/YYYY'
                                    )
                                }}
                            >
                                <input
                                    onChange={(e) => {
                                        if (e.target.value.length === 0) {
                                            setDates({
                                                startDate: null,
                                                endDate: null
                                            });
                                        }
                                    }}
                                    defaultValue={`${moment(
                                        dates.startDate
                                    ).format('DD/MM/YYYY')} - ${moment(
                                        dates.endDate
                                    ).format('DD/MM/YYYY')}`}
                                    type="text"
                                    className="form-control"
                                />
                            </DateRangePicker>
                        </Card.Header>
                        <h4 style={{ textAlign: 'center' }}>
                            {`Tổng số bài viết tạo từ ngày ${moment(
                                dates.startDate
                            ).format('DD/MM/YYYY')} - ${moment(
                                dates.endDate
                            ).format('DD/MM/YYYY')}`}{' '}
                        </h4>
                        <Line
                            data={{
                                labels: labelsPostChart,
                                datasets: [
                                    {
                                        data: dataPostChart,
                                        label: 'Tổng số bài viết',
                                        borderColor: '#3e95cd',
                                        fill: true
                                    }
                                ]
                            }}
                            options={{
                                title: {
                                    display: true,
                                    text: 'World population per region (in millions)'
                                },
                                legend: {
                                    display: true,
                                    position: 'bottom'
                                }
                            }}
                        />
                    </Card>
                </div>
            </div>
            {/* file HTML5 tắt quảng cáo được */}
            {/* <div style={{ display: 'block', width: 400, height: 400, overflow: 'hidden', position: "relative" }}>
                <iframe onScroll="no" style={{ width: '100%', height: '100%', overflow: 'hidden' }} title="Tập đoàn dầu khí việt nam" src="http://dev.vibiz.info/document/petrolimex_300x250/petrolimex_300x250.html">frame body
                </iframe>
            </div> */}

            {/* file image không tắt quảng cáo được */}
            {/* <div style={{ display: 'block', width: 400, height: 400, overflow: 'hidden', position: "relative" }}>
                <iframe onScroll="no" style={{ width: '100%', height: '100%', overflow: 'hidden' }} title="Tập đoàn dầu khí việt nam" src="http://dev.vibiz.info/images/6_20211111075249_thumb.jpg">frame body
                </iframe>
            </div> */}

            <div style={{ marginBottom: '1rem' }} className="row">
                <div className="col-lg-6 col-6">
                    <Card style={{ height: '100%' }}>
                        <Card.Header style={{ fontSize: 25 }}>
                            Hoạt động gần đây
                        </Card.Header>
                        <div
                            style={{
                                height: '100%'
                            }}
                            className="center-flex"
                        >
                            <h5>Không có dữ liệu</h5>
                        </div>
                    </Card>
                </div>
                <div className="col-lg-6 col-6">
                    <Card style={{ height: '100%' }}>
                        <Card.Header style={{ fontSize: 25 }}>
                            Thống kê truy cập
                        </Card.Header>
                        <Line
                            data={{
                                labels: labelsWebAnalyChart,
                                datasets: [
                                    {
                                        data: dataWebAnalyChart,
                                        label: 'Tống số truy cập',
                                        borderColor: '#3e95cd',
                                        fill: true
                                    }
                                ]
                            }}
                            options={{
                                title: {
                                    display: true,
                                    text: 'World population per region (in millions)'
                                },
                                legend: {
                                    display: true,
                                    position: 'bottom'
                                }
                            }}
                        />
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-6">
                    <Card>
                        <Card.Header style={{ fontSize: 25 }}>
                            Bình luận
                        </Card.Header>
                        <div>
                            <h5 style={{ textAlign: 'center' }}>
                                Không có dữ liệu
                            </h5>
                        </div>
                    </Card>
                </div>
                <div className="col-lg-6 col-6">
                    <Card>
                        <Card.Header style={{ fontSize: 25 }}>
                            Liên hệ
                        </Card.Header>
                        <div>
                            <h5 style={{ textAlign: 'center' }}>
                                Không có dữ liệu
                            </h5>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
