import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {EMAILCONFIG} from '../constants';
import {Get, Post, Put, DeleteSingle} from '../../utils/httpProvider';
import {EMAILCONFIG_API} from '../../utils/apiUrl';

export const emailConfigPagingAction = createAsyncThunk(
    EMAILCONFIG.GET_PAGING,
    async (model) => {
        return Get(EMAILCONFIG_API.GET_PAGING_EMAILCONFIG, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const emailConfigGetItemAction = createAsyncThunk(
    EMAILCONFIG.GET_ITEM,
    async (id) => {
        return Get(EMAILCONFIG_API.GET_ITEM_EMAILCONFIG, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const addEmailConfigAction = createAsyncThunk(
    EMAILCONFIG.ADD_EMAILCONFIG,
    async (model) => {
        return Post(EMAILCONFIG_API.ADD_EMAILCONFIG, null, model, false)
            .then(async (response) => {
                return response.status;
                // if (response.status === 200 || response.status === 201) {
                //     return fulfillWithValue(response.status);
                // }
                // return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const editEmailConfigAction = createAsyncThunk(
    EMAILCONFIG.EDIT_EMAILCONFIG,
    async (model) => {
        return Put(EMAILCONFIG_API.UPDATE_EMAILCONFIG, model.id, model.data)
            .then(async (response) => {
                return response.status;
                // if (response.status === 200 || response.status === 201) {
                //     return fulfillWithValue(response.status);
                // }
                // return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const deleteEmailConfigAction = createAsyncThunk(
    EMAILCONFIG.DELETE_EMAILCONFIG,
    async (id, {rejectWithValue, fulfillWithValue}) => {
        return DeleteSingle(EMAILCONFIG_API.DELETE_EMAILCONFIG, id, null)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

const initialState = {
    listEmailConfig: [],
    totalEmailConfig: 0,
    emailConfigItem: null
};
export const emailConfigSlice = createSlice({
    name: EMAILCONFIG.NAME_SLICE,
    initialState,
    reducers: {
        filterListEmailConfig: (state, action) => {
            state.listEmailConfig =
                state.listEmailConfig.length > 0
                    ? state.listEmailConfig.filter(
                          (x) => x.id !== action.payload
                      )
                    : [];
            state.totalEmailConfig -= 1;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(emailConfigPagingAction.fulfilled, (state, action) => {
                state.listEmailConfig =
                    action.payload &&
                    action.payload.data &&
                    action.payload.data.$values
                        ? action.payload.data.$values
                        : [];
                state.totalEmailConfig =
                    action.payload && action.payload.totalRecords
                        ? action.payload.totalRecords
                        : 0;
            })
            .addCase(emailConfigGetItemAction.fulfilled, (state, action) => {
                state.emailConfigItem = action.payload;
            })
            .addCase(emailConfigGetItemAction.rejected, (state) => {
                state.emailConfigItem = null;
            });
    }
});

export const {filterListEmailConfig} = emailConfigSlice.actions;

export default emailConfigSlice.reducer;
