import React from 'react';
import PropTypes from 'prop-types';
import {CKEditor as CKEditor5} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {uploadAdapterPlugin} from './CKEditorUploadAdapter';

const Editor = (props) => {
    const {name, value, onChange} = props;
    return (
        <CKEditor5
            editor={ClassicEditor}
            data={value}
            name={name}
            config={{
                extraPlugins: [uploadAdapterPlugin]
            }}
            onChange={(event, editor) => {
                onChange(editor.getData());
            }}
        />
    );
};

Editor.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string
};

Editor.defaultProps = {
    value: '',
    name: ''
};

export default Editor;
