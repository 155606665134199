import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { loginHistoryStatisticAction } from '@store/reducers/loginHistory';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Table from 'react-bootstrap/Table';
import { dateTimeToStringRequest,exportExcel } from '@app/utils/helper';
import { rangeDate } from '@app/utils/define';

const StatisticLoginHistory = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const statisticLoginHistory = useSelector(
        (state) => state.loginHistory.statisticLoginHistory
    );
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    useEffect(() => {
        const model = {
            fromDate: dates.startDate
                ? dateTimeToStringRequest(dates.startDate.toDate())
                : '',
            toDate: dates.endDate
                ? dateTimeToStringRequest(dates.endDate.toDate())
                : ''
        };
        dispatch(loginHistoryStatisticAction(model));
    }, [dates]);

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {' '}
                                Thống kê đăng nhập theo từng tài khoản{' '}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between marbottom-10">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Thời gian đăng nhập :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate
                                        }}
                                    >
                                        <input
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 0
                                                ) {
                                                    setDates({
                                                        startDate: null,
                                                        endDate: null
                                                    });
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                </form>
                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to
                                    onClick={() => exportExcel(`baocao_dangnhap_${Date.now()}`)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Xuất file Excel
                                    </button>
                                </NavLink>

                            </div>
                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap4"
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Table
                                            id="table-to-xls"
                                            striped
                                            bordered
                                            hover
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting">
                                                        Tài khoản
                                                    </th>
                                                    <th className="sorting">
                                                        Tổng lượt đăng nhập
                                                    </th>
                                                </tr>
                                            </thead>
                                            {statisticLoginHistory != null &&
                                                statisticLoginHistory !==
                                                undefined &&
                                                statisticLoginHistory.length > 0 ? (
                                                <tbody>
                                                    {statisticLoginHistory.map(
                                                        (item, index) => (
                                                            <tr
                                                                key={Number(
                                                                    index
                                                                )}
                                                                className="odd"
                                                            >
                                                                <td>
                                                                    {
                                                                        item.userName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.totalLogin.toString()}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr className="txt-center">
                                                        <td colSpan="12">
                                                            Không có dữ liệu
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatisticLoginHistory;
