import React, {useEffect, useState, useRef} from 'react';
import {Button} from '@components';
import {Image, ButtonGroup, ProgressBar, Form, Spinner} from 'react-bootstrap';
import {BaseGatewayImage, FILE_MANAGER_API} from '@app/utils/apiUrl';
import axios from 'axios';
import {getToken} from '@app/utils/helper';

const uploadFile = async ({fileUpload, setCompleted}) => {
    const token = getToken();
    const config = {
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            setCompleted(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `bearer ${token}`
        }
    };

    const data = new FormData();
    data.append('file', fileUpload);

    try {
        const res = await axios.post(FILE_MANAGER_API.UPLOAD, data, config);
        return res;
    } catch (error) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
        return errorMessage;
    }
};

const PreviewFileUpload = ({
    isThumbnail = false,
    fileView,
    uploaded,
    actionRemove
}) => {
    const [completed, setCompleted] = useState(0);
    const [file, setFile] = useState(fileView);
    const inputUpload = useRef();

    const handleUploadFile = async (fileUpload) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            fileUpload.url = e.target.result;
            setFile(fileUpload);
        };
        reader.readAsDataURL(fileUpload);
        const result = await uploadFile({fileUpload, setCompleted});
        const fileUpdated = result.data.$values[0];
        fileUpdated.url = BaseGatewayImage + fileUpdated.thumbPath;
        fileUpdated.type = fileUpdated.fileType;
        uploaded(fileUpdated);
        setFile(fileUpdated);
        setCompleted(0);
    };

    useEffect(async () => {
        if (fileView != null && fileView.url == null) {
            handleUploadFile(fileView);
        }
    }, []);

    useEffect(() => {
        if (fileView != null && fileView.url == null && file.url) {
            handleUploadFile(fileView);
        } else {
            setFile(fileView);
        }
    }, [fileView]);

    const handleUpdateFile = async (event) => {
        const fileUpdate = event.target.files[0];
        handleUploadFile(fileUpdate);
    };

    const isUploaded = file.url && completed === 0;

    return isThumbnail ? (
        <div className="mgl5 mgt5 mgr5 mgbt5 position-relative">
            <div className="imageThumb">
                <Image
                    src={file.url}
                    rounded
                    style={{opacity: isUploaded ? 1 : 0.5}}
                />
                <Spinner
                    animation="border"
                    className="position-absolute"
                    style={{
                        display: isUploaded ? 'none' : 'block',
                        top: '50%',
                        left: '50%',
                        marginLeft: '-15px',
                        marginTop: '-15px'
                    }}
                />
                <i
                    className="fas fa-times mr-2 position-absolute"
                    style={{
                        right: '-2.5px',
                        top: '5px',
                        zIndex: 9,
                        display: !isUploaded ? 'none' : 'block'
                    }}
                    role="presentation"
                    onClick={() => actionRemove()}
                />
            </div>
        </div>
    ) : (
        <>
            <Image
                src={file.url}
                thumbnail
                className={`col-sm-2 ${
                    !file.type.includes('image') && 'd-none'
                }`}
            />
            {!file.type.includes('image') && (
                <div className="d-flex align-items-center">
                    <i className="fas fa-file" />
                </div>
            )}

            <span className="col  ml-3 d-flex align-items-center">
                {file.name}
            </span>
            <div className="col-4 d-flex align-items-center">
                <ProgressBar
                    className="progress-striped active w-100"
                    style={{
                        display: isUploaded ? 'none' : 'flex'
                    }}
                    variant="success"
                    animated
                    now={completed}
                />
            </div>
            <ButtonGroup className="col-auto d-flex align-items-center">
                <Form.Control
                    type="file"
                    hidden
                    accept="image/*"
                    ref={inputUpload}
                    onChange={(e) => handleUpdateFile(e)}
                />
                <Button
                    className={`btn-success ${
                        file.type.includes('image') && 'd-none'
                    }`}
                    href={file.url}
                    target="_blank"
                    disabled={!isUploaded}
                >
                    <i className="fas fa-eye mr-2" />
                    <span>Xem</span>
                </Button>
                <Button
                    className="btn-primary"
                    onClick={() => inputUpload.current.click()}
                    disabled={!isUploaded}
                >
                    <i className="fas fa-upload mr-2" />
                    <span>Cập nhật</span>
                </Button>
                <Button
                    className="btn-danger"
                    onClick={actionRemove}
                    disabled={!isUploaded}
                >
                    <i className="fas fa-trash mr-2" />
                    <span>Xóa</span>
                </Button>
            </ButtonGroup>
        </>
    );
};

export default PreviewFileUpload;
