import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from 'react-js-pagination';
import { useMutation, useQuery } from 'react-query';
import { Post, Get } from '@app/utils/httpProvider';
import { ACCOUNT_API, WEB_ANALYSIS } from '@app/utils/apiUrl';
import Table from 'react-bootstrap/Table';
import * as moment from 'moment';
import SelectTree from '@app/components/select-tree/SelectTree';
import { getStatisticPostSynthticAction } from '../../../store/reducers/reportPost';
import {
    objectStatusPost,
    objectDataTypeCategory,
    rangeDate
} from '../../../utils/define';
import { dateTimeToStringRequest, exportExcel, subInput } from '../../../utils/helper';

const ReportViewByCate = ({ isActive }) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(1000);

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['lstCateData', page, pageSize],
        () => {
            const model = {
                pageIndex: page,
                pageSize: pageSize
            };
            return Get(WEB_ANALYSIS.COUNT_VISITS_DETAIL_CATE, null, model);
        }
    );

    useEffect(() => {
        refetch();
    }, [page]);

    // useEffect(() => {
    //     const modelPost = {
    //         dataTypeCategory: valueDateType,
    //         fromDate: dates.startDate
    //             ? dateTimeToStringRequest(dates.startDate.toDate())
    //             : '',
    //         toDate: dates.endDate
    //             ? dateTimeToStringRequest(dates.endDate.toDate())
    //             : ''
    //     };
    //     dispatch(getStatisticPostSynthticAction(modelPost));
    // }, [valueDateType, dates]);

    const changePage = (number) => {
        setPage(number);
    };

    const countTotalViews = () => {
        let totalViews = 0;

        if (data &&
            data.data != null &&
            data.data !== undefined &&
            data.data.$values.length > 0) {
            for (let i = 0; i < data.data.$values.length; i++) {
                totalViews += (data.data.$values[i].views ? data.data.$values[i].views : 0);
            }
        }

        return totalViews;

    }

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h1 className="card-title">
                                    {' '}
                                    Báo cáo lượt xem theo danh mục
                                </h1>
                            </div>
                            <div className="card-body">
                                <div
                                    className="d-flex"
                                    style={{ justifyContent: 'end' }}
                                >
                                    <NavLink
                                        className="nav-link-reject nav-link"
                                        to
                                        onClick={() => exportExcel(`baocao_luotxemtheodanhmuc_${Date.now()}`)}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            Xuất file Excel
                                        </button>
                                    </NavLink>
                                </div>
                                {isLoading ? (
                                    <div>Loading...</div>
                                ) : isError ? (
                                    <div>
                                        An error has occurred: ${error.message}
                                    </div>
                                ) : (
                                    <div
                                        id="example2_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4"
                                    >
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Table id="table-to-xls">
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="sorting">
                                                                STT
                                                            </th>
                                                            <th className="sorting">
                                                                Danh mục
                                                            </th>
                                                            <th className="sorting">
                                                                Lượt xem
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {data &&
                                                        data.data != null &&
                                                        data.data !== undefined &&
                                                        data.data.$values.length > 0 ? (
                                                        <tbody>
                                                            {data.data.$values.map(
                                                                (item, index) => (
                                                                    <tr role="row" key={Number(
                                                                        index
                                                                    )}
                                                                        className="odd">
                                                                        <td className="sorting">
                                                                            {index +
                                                                                1 +
                                                                                (page -
                                                                                    1) *
                                                                                pageSize}
                                                                        </td>
                                                                        <td className="sorting">
                                                                            {
                                                                                item.name ? subInput(item.name, 50) : ""
                                                                            }
                                                                        </td>
                                                                        <td className="sorting">
                                                                            {
                                                                                item.views ? item.views : 0
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            <tr role="row">
                                                                <td
                                                                    colSpan="2"
                                                                    className="sorting"
                                                                >
                                                                    Tổng tất cả lượt xem
                                                                </td>
                                                                <td>{countTotalViews()}</td>
                                                            </tr>
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            <tr className="txt-center">
                                                                <td colSpan="12">
                                                                    Không có dữ liệu
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers"
                                                    id="example2_paginate"
                                                >
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        innerClass="pagination pagination-sm no-margin pull-right"
                                                        activePage={page}
                                                        itemsCountPerPage={Number(
                                                            pageSize
                                                        )}
                                                        totalItemsCount={
                                                            data ? data.totalRecords : null
                                                        }
                                                        // totalItemsCount={100}
                                                        pageRangeDisplayed={5}
                                                        onChange={(number) =>
                                                            changePage(number)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportViewByCate;
