import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Post } from '@app/utils/httpProvider';
import { PARTMER_API } from '@app/utils/apiUrl';
import { useMutation } from 'react-query';
import {
    messageError,
    messageToast,
    status200,
    acceptImage
} from '../../utils/define';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage)
});

const PartnerAdd = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const addFunc = useMutation((value) =>
        Post(PARTMER_API.ADD_PARTMER, null, value, false)
    );

    const handleCreate = async (values) => {
        const model = values;
        // model.imagePath = imagePath;
        // model.imageThumbnail = imageThumbnail;

        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    setIsLoading(true);
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm đối tác</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        name: '',
                        redirectLink: '',
                        imagePath: '',
                        imageThumbnail: '',
                        orderNumber: 0,
                        isActive: false
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tên *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tên"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Link trở về
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Link trở về"
                                        type="text"
                                        name="redirectLink"
                                        value={values.redirectLink}
                                        onChange={handleChange}
                                        isInvalid={!!errors.redirectLink}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.redirectLink}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Ảnh *
                                </Form.Label>
                                <Col sm="10">
                                    <InputFile
                                        name="imageThumbnail"
                                        accept={acceptImage}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                setFieldValue(
                                                    'imagePath',
                                                    files[0].filePath,
                                                    true
                                                );
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files[0].thumbPath,
                                                    true
                                                );
                                            }
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.imageThumbnail}
                                    </div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.parentId}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Trạng thái kích hoạt
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary1"
                                        type="checkbox"
                                        placeholder="Trạng thái"
                                        name="isActive"
                                        checked={values.isActive}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary1">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Sắp xếp
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="orderNumber"
                                        placeholder="Sắp xếp"
                                        value={values.orderNumber}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                            disabled={isLoading}
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <ReactSpinner loadings={isLoading} />
            </Card.Body>
        </Card>
    );
};

export default PartnerAdd;
