import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ACCOUNT } from '../constants';
import { Get, Post, Put, DeleteSingle } from '../../utils/httpProvider';
import { ACCOUNT_API } from '../../utils/apiUrl';

export const accountPagingAction = createAsyncThunk(
    ACCOUNT.GET_PAGING,
    async (model) => {
        return Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const addAccountAction = createAsyncThunk(
    ACCOUNT.ADD_ACCOUNT,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Post(ACCOUNT_API.ADD_ACCOUNT, null, model, false)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);
export const getByIdAccountAction = createAsyncThunk(
    ACCOUNT.GET_BY_ID_ACCOUNT,
    async (id) => {
        return Get(ACCOUNT_API.GET_BY_ID_ACCOUNT, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const updateAccountAction = createAsyncThunk(
    ACCOUNT.UPDATE_ACCOUNT_CONST,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Put(ACCOUNT_API.EDIT_ACCOUNT, model.id, model.data)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);
export const deleteAccountAction = createAsyncThunk(
    ACCOUNT.DELETE_ACCOUNT_CONST,
    async (id, { rejectWithValue, fulfillWithValue }) => {
        return DeleteSingle(ACCOUNT_API.DELETE_ACCOUNT, id, null)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);
const initialState = {
    listAccount: [],
    totalAccount: 0,
    itemAccount: null
};
export const accountSlice = createSlice({
    name: ACCOUNT.NAME_SLICE,
    initialState,
    reducers: {
        // filter lúc xóa bản ghi thành công
        filterListAccount: (state, action) => {
            state.listAccount =
                state.listAccount.length > 0
                    ? state.listAccount.filter((x) => x.id !== action.payload)
                    : [];
            state.totalAccount -= 1;
        },
        // cập nhật lại thông tin tài khoản trong local khi update
        reloadAccount: (state, action) => {
            const itemAccountNews = { ...state.itemAccount };
            itemAccountNews.fullName = action.payload.fullname;
            itemAccountNews.email = action.payload.email;
            state.itemAccount = itemAccountNews;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(accountPagingAction.fulfilled, (state, action) => {
                if (action.payload && action.payload.data && action.payload.totalRecords) {
                    state.listAccount = action.payload.data
                        ? action.payload.data.$values
                        : [];
                    state.totalAccount = action.payload.totalRecords;
                }

            })
            .addCase(getByIdAccountAction.fulfilled, (state, action) => {
                if(action.payload){
                    state.itemAccount = action.payload;
                }
            })
            .addCase(getByIdAccountAction.rejected, (state) => {
                state.itemAccount = null;
            });
    }
});
export const { filterListAccount, reloadAccount } = accountSlice.actions;

export default accountSlice.reducer;
