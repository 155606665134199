import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup, Container, Image, CloseButton } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import DatePicker from 'react-datepicker';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import SelectTree from '@app/components/select-tree/SelectTree';
import { NIL as NIL_UUID, v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { dateTimeToUTCRequest, getInfomationAccountFromToken, getToken } from '@app/utils/helper';
import {
    messageError,
    messageToast,
    acceptImage,
    objectStatusPost,
    styleSelectDefault,
    styleSelectError,
    dataTypeCategory,
    DATE_TIME_REACT_FORMAT,
    role,
    postStatus
} from '@app/utils/define';
import { BaseGatewayImage, POST_API, CATEGORY_API, FILE_MANAGER_API } from '@app/utils/apiUrl';
import ReactSpinner from '@app/components/reactSpinner/ReactSpinner';
import VideoInput from '@app/components/video-input/VideoInput';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage),
    postStatus: yup.object().required(messageError.emptyMessage),
    // videoLink: yup.string().required(messageError.emptyMessage)
    listImageFromik: yup.array().min(1, messageError.emptyMessage)
});

const LibraryImageEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const inputUpload = useRef();
    const location = useLocation();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [isShowErrorLstImage, setIsShowErrorLstImage] = useState(false);
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const imageId = paramUrl.get('id');
    const [lstImages, setLstImage] = useState([]);

    const postItem = useQuery([imageId], () =>
        Get(POST_API.GET_ITEM_POST, imageId)
    );

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const addMutation = useMutation((value) =>
        Put(POST_API.UPDATE_POST, value.id, value.data)
    );

    let objectStatusFilter;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        if (userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher)) {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all);
        } else {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all && x.value !== postStatus.release);
        }
    }


    const handleEdit = async (values) => {
        const model = JSON.parse(JSON.stringify(values));
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        model.imagePath = values.imagePath;
        model.imageThumbnail = values.imageThumbnail;
        model.publishTime = dateTimeToUTCRequest(values.publishTime);
        model.status = values.postStatus.value;
        model.lstImages = values.listImageFromik;
        model.dataType = listCate.data.data.$values.find(
            (x) => x.id === values.categoryId
        ).dataType;
        const modelUpdate = {
            data: model,
            id: imageId
        };
        addMutation.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== 200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    queryClient.setQueryData(imageId, modelUpdate);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const isLoading = postItem.isLoading || listCate.isLoading;
    const isError = postItem.isError || listCate.isError;
    const error = postItem.isError ? postItem.error : listCate.error;

    let menuData = [];
    if (!listCate.isLoading && !listCate.isError) {
        menuData = listCate.data
            ? menuData.concat(
                listCate.data.data.$values.filter(
                    (x) => x.dataType === dataTypeCategory.image
                )
            )
            : [];
    }
    // upload data image
    const uploadFile = async ({ fileUpload }) => {
        const token = getToken();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `bearer ${token}`
            }
        };
        const data = new FormData();
        data.append('file', fileUpload);
        try {
            const res = await axios.post(FILE_MANAGER_API.UPLOAD, data, config);
            return res;
        } catch (error) {
            const errorMessage = error.response.data.message;
            return errorMessage;
        }
    };

    const handleUploadFile = async (e, listCurrent, functionSetField) => {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            const result = [];
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size < 1024000 * 30) {
                    const fileUpload = e.target.files[i];
                    result.push((uploadFile({ fileUpload })));
                } else {
                    toast.error("Kích thước ảnh quá lớn, hãy thử lại với ảnh nhỏ hơn 30mb");
                }
            }
            const responses = await Promise.all(result);
            // const lstCurentImage = JSON.parse(JSON.stringify(lstImages))
            const lstCurentImage = listCurrent;
            responses.forEach((item) => {
                const fileUpdated = item.data.$values[0];
                const modelImage = {
                    "id": uuidv4(),
                    "imagePath": fileUpdated.filePath,
                    "note": "",
                    "oder": "1"
                }
                lstCurentImage.push(modelImage);
            })
            // setIsShowErrorLstImage(false);
            // setLstImage(lstCurentImage);
            functionSetField(
                'listImageFromik',
                // lstCurentImage.sort((a, b) => a.oder - b.oder),
                lstCurentImage,
                true
            );
        }
    };

    // const onClickDeleteImage = (idImage) => {
    //     if (idImage) {
    //         let lstCurentImage = JSON.parse(JSON.stringify(lstImages));
    //         lstCurentImage = lstCurentImage.filter(x => x.id !== idImage);
    //         setLstImage(lstCurentImage);
    //     }
    // }
    // const onClickAddNote = (idImage) => {
    //     if (idImage) {
    //         const valueDomInput = document.getElementById(idImage).value;
    //         const lstCurentImage = JSON.parse(JSON.stringify(lstImages));
    //         lstCurentImage.find(x => x.id === idImage).note = valueDomInput;
    //         setLstImage(lstCurentImage);
    //     }
    // }

    // useEffect(() => {
    //     if (postItem.data && postItem.data.lstImages) {
    //         if (postItem.data.lstImages.length > 0) {
    //             const lstNew = JSON.parse(postItem.data.lstImages).map(x => ({
    //                 id: x.Id,
    //                 imagePath: x.ImagePath,
    //                 note: x.Note,
    //                 oder: x.Oder
    //             }));
    //             setLstImage(lstNew);
    //         }
    //     }
    // }, [])
    return (
        <Card>
            <Card.Header as="h5">Sửa thông thư viện ảnh</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : postItem.data == null ? (
                    <div>Thư viện ảnh không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            title:
                                postItem.data && postItem.data.title
                                    ? postItem.data.title
                                    : '',
                            description:
                                postItem.data && postItem.data.description
                                    ? postItem.data.description
                                    : '',
                            keywords:
                                postItem.data && postItem.data.keywords
                                    ? postItem.data.keywords
                                    : '',
                            ogTitle:
                                postItem.data && postItem.data.ogTitle
                                    ? postItem.data.ogTitle
                                    : '',
                            ogDescription:
                                postItem.data && postItem.data.ogDescription
                                    ? postItem.data.ogDescription
                                    : '',
                            articleTag:
                                postItem.data && postItem.data.articleTag
                                    ? postItem.data.articleTag
                                    : '',
                            content:
                                postItem.data && postItem.data.content
                                    ? postItem.data.content
                                    : '',
                            videoLink:
                                postItem.data && postItem.data.videoLink
                                    ? postItem.data.videoLink
                                    : '',
                            isFocus:
                                postItem.data && postItem.data.isFocus
                                    ? postItem.data.isFocus
                                    : false,
                            virtualViews: postItem.data && postItem.data.virtualViews
                                ? postItem.data.virtualViews
                                : 0,
                            imageThumbnail:
                                postItem.data && postItem.data.imageThumbnail
                                    ? postItem.data.imageThumbnail
                                    : '',
                            imagePath:
                                postItem.data && postItem.data.imagePath
                                    ? postItem.data.imagePath
                                    : '',
                            postStatus: postItem.data
                                ? objectStatusPost.find(
                                    (x) => x.value === postItem.data.status
                                )
                                : '',
                            categoryId:
                                postItem.data.categoryId === NIL_UUID
                                    ? 'null'
                                    : postItem.data.categoryId,
                            publishTime:
                                postItem.data && postItem.data.publishTime
                                    ? new Date(postItem.data.publishTime)
                                    : new Date(),
                            listImageFromik:
                                postItem.data && postItem.data.lstImages ?
                                    JSON.parse(postItem.data.lstImages).map(x => ({
                                        id: x.Id,
                                        imagePath: x.ImagePath,
                                        note: x.Note,
                                        oder: x.Oder
                                    })).sort((a, b) => a.oder - b.oder) : []
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục *
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={menuData}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={menuData.find(
                                                (x) =>
                                                    x.id === values.categoryId
                                            )}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Upload ảnh (*)
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            multiple
                                            type="file"
                                            hidden
                                            accept={acceptImage}
                                            ref={inputUpload}
                                            onChange={(e) => handleUploadFile(e, values.listImageFromik, setFieldValue)}
                                        />
                                        <button type="button" className="w-auto ms-2" onClick={() => inputUpload.current.click()}>Chọn file tải lên</button>
                                        {
                                            values.listImageFromik && values.listImageFromik.length > 0 ?
                                                <div style={{ width: '60%', overflow: 'auto', height: "400px", display: 'flex', flexWrap: 'wrap' }}>
                                                    {values.listImageFromik.map((item, index) =>
                                                        <Card key={Number(index)} style={{ width: '30%', marginLeft: '2%' }}>
                                                            <div style={{ margin: "10%" }}>
                                                                <Image
                                                                    src={BaseGatewayImage + item.imagePath}
                                                                    height={150}
                                                                    width="100%"
                                                                    size
                                                                    rounded
                                                                />
                                                            </div>
                                                            <div style={{ margin: "5%" }}>
                                                                <Form.Control type="text" id={item.id} placeholder="" defaultValue={item.note} />
                                                            </div>
                                                            <div style={{ margin: "5%", display: 'flex', justifyContent: 'space-between' }}>
                                                                {/* <Button onClick={() => onClickAddNote(item.id)} variant="outline-dark" title="Thông tin bổ sung">+</Button> */}
                                                                {/* Thêm thông tin bổ sung cho ảnh */}
                                                                <Button onClick={() => {
                                                                    const valueDomInput = document.getElementById(item.id).value;
                                                                    const lstCurentImage = values.listImageFromik;
                                                                    lstCurentImage.find(x => x.id === item.id).note = valueDomInput;
                                                                    toast.success("Đã thêm thông tin bổ sung");
                                                                    setFieldValue(
                                                                        'listImageFromik',
                                                                        lstCurentImage,
                                                                        true
                                                                    );
                                                                }} variant="outline-dark" title="Thông tin bổ sung">+</Button>
                                                                <div>

                                                                    {/* Thêm oder cho image */}
                                                                    <Form.Control min="1" type="number" value={item.oder} onChange={(e) => {
                                                                        const lstCurentImage = values.listImageFromik;
                                                                        lstCurentImage.find(x => x.id === item.id).oder = e.target.value;
                                                                        setFieldValue(
                                                                            'listImageFromik',
                                                                            lstCurentImage,
                                                                            true
                                                                        );
                                                                    }} style={{ width: '60px', display: 'inline-block', marginRight: '5px' }} placeholder="" />

                                                                    {/* <Button onClick={() => onClickDeleteImage(item.id)} variant="danger" title="Xóa">Xóa</Button> */}
                                                                    {/* Xóa 1 image trong list */}
                                                                    <button onClick={() => {
                                                                        let lstCurentImage = values.listImageFromik;
                                                                        lstCurentImage = lstCurentImage.filter(x => x.id !== item.id);
                                                                        setFieldValue(
                                                                            'listImageFromik',
                                                                            lstCurentImage,
                                                                            true
                                                                        );
                                                                    }
                                                                    } type="button"
                                                                    className="btn btn-danger" variant="danger" title="Xóa">Xóa</button>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div> :
                                                null
                                        }
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                        >
                                            {errors.listImageFromik}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectStatusFilter}
                                            styles={
                                                errors.postStatus !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh đại diện *
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            values={
                                                values.imageThumbnail !== ''
                                                    ? [
                                                        BaseGatewayImage +
                                                        values.imageThumbnail
                                                    ]
                                                    : []
                                            }
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files.length > 0
                                                        ? files[0].thumbPath
                                                        : '',
                                                    true
                                                );
                                                setFieldValue(
                                                    'imagePath',
                                                    files.length > 0
                                                        ? files[0].filePath
                                                        : '',
                                                    true
                                                );
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian xuất bản
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <DatePicker
                                            selected={values.publishTime}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'publishTime',
                                                    date,
                                                    true
                                                );
                                            }}
                                            timeInputLabel="Time:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tóm tắt
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="content"
                                            value={values.content}
                                            onChange={(value) => {
                                                setFieldValue('content', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nguồn link
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Nguồn link"
                                            type="text"
                                            name="sourceLink"
                                            value={values.sourceLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.sourceLink}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Là tiêu điểm
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Là tiêu điểm"
                                            name="isFocus"
                                            checked={values.isFocus}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Lượt view ảo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="virtualViews"
                                            placeholder=""
                                            value={values.virtualViews}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ khóa Meta
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Từ khóa"
                                            type="text"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                            isInvalid={!!errors.keywords}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.keywords}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề Og Meta
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề Og"
                                            type="text"
                                            name="ogTitle"
                                            value={values.ogTitle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogTitle}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả Og Meta
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mô tả Og"
                                            type="text"
                                            name="ogDescription"
                                            value={values.ogDescription}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tag bài báo Meta
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tag bài báo"
                                            type="text"
                                            name="articleTag"
                                            value={values.articleTag}
                                            onChange={handleChange}
                                            isInvalid={!!errors.articleTag}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.articleTag}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                {/* <ReactSpinner loadings={isLoadingEdit} /> */}
            </Card.Body>
        </Card>
    );
};

export default LibraryImageEdit;
