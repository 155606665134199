import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {CATEGORY} from '../constants';
import {Get, Post, Put, DeleteSingle} from '../../utils/httpProvider';
import {CATEGORY_API} from '../../utils/apiUrl';

export const categoryPagingAction = createAsyncThunk(
    CATEGORY.GET_PAGING,
    async (model) => {
        return Get(CATEGORY_API.GET_PAGING_CATEGORY, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

const initialState = {
    listCategory: [],
    totalCategory: 0,
    categoryItem: null
};
export const categorySlice = createSlice({
    name: CATEGORY.NAME_SLICE,
    initialState,
    reducers: {
        filterListCategory: (state, action) => {
            state.listCategory =
                state.listCategory.length > 0
                    ? state.listCategory.filter((x) => x.id !== action.payload)
                    : [];
            state.totalCategory -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(categoryPagingAction.fulfilled, (state, action) => {
            state.listCategory =
                action.payload &&
                action.payload.data &&
                action.payload.data.$values
                    ? action.payload.data.$values
                    : [];
            state.totalCategory =
                action.payload && action.payload.totalRecords
                    ? action.payload.totalRecords
                    : 0;
        });
    }
});

export const {filterListCategory} = categorySlice.actions;

export default categorySlice.reducer;
