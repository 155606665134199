import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import SelectTree from '@app/components/select-tree/SelectTree';
import { BANNER_API, CATEGORY_API } from '@app/utils/apiUrl';
import { Get, Post, Put } from '@app/utils/httpProvider';
import { useMutation, useQuery } from 'react-query';
import { NIL as NIL_UUID } from 'uuid';
import { URI_INTERNAL } from '../../utils/pathLocations';
import {
    acceptImage,
    positionBanner,
    messageToast,
    status200,
    messageError
} from '../../utils/define';
import { BaseGatewayImage } from '../../utils/apiUrl';

const schema = yup.object().shape({
    categoryIds: yup.string().required(messageError.emptyMessage)
});

const Banner = () => {
    const history = useHistory();
    const location = useLocation();

    const [cateId, setCateId] = useState('null');

    const [values, setValues] = useState({
        categoryId: 'null',
        header: [],
        bothSides: [],
        rightColumn: []
    });

    console.log(values);

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY, null, {})
    );

    const itemBanner = useQuery(['itemBanner', cateId], () =>
        Get(
            BANNER_API.GET_ITEM_BY_CATE_API,
            cateId === 'null' ? NIL_UUID : cateId,
            null
        )
    );

    const addBanner = useMutation((value) =>
        Post(BANNER_API.CREATE_BANNER_API, null, value)
    );

    const updateBanner = useMutation((value) =>
        Put(BANNER_API.UPDATE_BANNER_API, value.id, value)
    );

    let listMenu = [
        {
            name: 'Tất cả',
            id: 'null',
            parentId: NIL_UUID
        }
    ];
    if (listCate.data) {
        listMenu = listMenu.concat(listCate.data.data.$values);
    }

    useEffect(() => {
        if (
            itemBanner.data &&
            itemBanner.data.categoryId !== values.categoryId
        ) {
            const valuesNew = {
                categoryId: itemBanner.data.categoryId,
                header: [...itemBanner.data.header.$values],
                bothSides: [...itemBanner.data.bothSides.$values],
                rightColumn: [...itemBanner.data.rightColumn.$values]
            };
            setValues(valuesNew);
        }
    }, [itemBanner]);

    useEffect(() => {
        if (cateId !== values.categoryId) {
            itemBanner.refetch();
        }
    }, [cateId]);

    const handleSave = () => {
        values.categoryId = cateId === 'null' ? itemBanner.data ? itemBanner.data.categoryId : '' : cateId;
        if (itemBanner.data.id !== NIL_UUID) {
            // const model = {
            //     id: itemBanner.id,
            //     data: values
            // };
            values.id = itemBanner.data.id;
            updateBanner.mutate(values, {
                onSuccess: (respone) => {
                    try {
                        if (respone.status === status200) {
                            itemBanner.refetch();
                            toast.success(
                                messageToast.messageToastUpdateSuccess
                            );
                        } else {
                            toast.errors(messageToast.messageToastUpdateError);
                        }
                    } catch (error) {
                        toast.error(messageToast.messageToastTryCatch);
                    }
                },
                onError: () => {
                    // bắt lỗi khi gặp ngoại lệ
                }
            });
        } else {
            addBanner.mutate(values, {
                onSuccess: (respone) => {
                    try {
                        if (respone.status === status200) {
                            itemBanner.refetch();
                            toast.success(
                                messageToast.messageToastCreateSuccess
                            );
                        } else {
                            toast.errors(messageToast.messageToastCreateError);
                        }
                    } catch (error) {
                        toast.error(messageToast.messageToastTryCatch);
                    }
                },
                onError: () => {
                    // bắt lỗi khi gặp ngoại lệ
                }
            });
        }
    };

    const handleChangeImage = (propertyName, files, banner, order) => {
        const valuesNew = { ...values };
        if (files.length > 0) {
            if (banner) {
                banner.imagePath = files[0].filePath;
                banner.imageThumb = files[0].thumbPath;
                valuesNew[propertyName].map((x) => {
                    if (x.orderNumber === banner.orderNumber) {
                        x = banner;
                    }
                    return x;
                });
            } else {
                const banner = {
                    imagePath: files[0].filePath,
                    imageThumb: files[0].thumbPath,
                    linkRedirect: '',
                    orderNumber: order
                };
                valuesNew[propertyName].push(banner);
            }
        } else {
            valuesNew[propertyName] = banner
                ? valuesNew[propertyName].filter(
                    (x) => x.orderNumber !== banner.orderNumber
                )
                : valuesNew[propertyName];
        }
        setValues(valuesNew);

    };

    const handleLinkRedirect = (propertyName, value, banner) => {
        const valuesNew = { ...values };
        banner.linkRedirect = value;
        valuesNew[propertyName].map((x) => {
            if (x.orderNumber === banner.orderNumber) {
                x = banner;
            }
            return x;
        });
        setValues(valuesNew);
    };

    const bannerHeader = values.header[0];
    const bannerRunLeft = values.bothSides.find((x) => x.orderNumber === 1);
    const bannerRunRight = values.bothSides.find((x) => x.orderNumber === 2);
    const bannerColRight1 = values.rightColumn.find((x) => x.orderNumber === 1);
    const bannerColRight2 = values.rightColumn.find((x) => x.orderNumber === 2);
    const bannerColRight3 = values.rightColumn.find((x) => x.orderNumber === 3);
    const bannerColRight4 = values.rightColumn.find((x) => x.orderNumber === 4);
    const bannerColRight5 = values.rightColumn.find((x) => x.orderNumber === 5);
    const bannerColRight6 = values.rightColumn.find((x) => x.orderNumber === 6);


    const renderBannerItem = (propertyName, title, banner, order) => {
        return (
            <>
                <div className="mb-2">{title}</div>
                <InputFile
                    accept={acceptImage}
                    enableReinitialize
                    values={
                        banner ? [BaseGatewayImage + banner.imageThumb] : []
                    }
                    onChange={(files) => {
                        handleChangeImage(propertyName, files, banner, order);
                    }}
                />
                <Form.Control
                    placeholder="Đường dẫn"
                    type="text"
                    name="redirectLink"
                    className="mt-2"
                    value={banner ? banner.linkRedirect : ''}
                    disabled={banner === null || banner === undefined}
                    style={{
                        width: 200
                    }}
                    onChange={(e) => {
                        handleLinkRedirect(
                            propertyName,
                            e.target.value,
                            banner
                        );
                    }}
                />
            </>
        );
    };

    return (
        <div className="container-fluid pb-2 pr-3">
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                        <SelectTree
                            data={listMenu}
                            showSearchBox
                            Placeholder="Danh mục"
                            positionExpand="right"
                            singleValueSelected={listMenu.find(
                                (x) => x.id === cateId
                            )}
                            onChange={(value) => {
                                setCateId(value.id);
                            }}
                        />
                        <div className="d-flex">
                            <NavLink
                                className="nav-link-reject nav-link"
                                to={URI_INTERNAL.BANNER_PATH}
                            >
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => handleSave()}
                                >
                                    Lưu
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </Card.Header>
            </Card>

            <Row>
                <Col xs={4}>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo Header</h5>
                        </Card.Header>
                        <Card.Body>
                            {renderBannerItem(
                                'header',
                                'Quảng cáo Header',
                                bannerHeader,
                                1
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo chạy 2 bên</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    {renderBannerItem(
                                        'bothSides',
                                        'Bên trái',
                                        bannerRunLeft,
                                        1
                                    )}
                                </Col>
                                <Col>
                                    {renderBannerItem(
                                        'bothSides',
                                        'Bên phải',
                                        bannerRunRight,
                                        2
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Card>
                <Card.Header>
                    <h5>Quảng cáo cột phải</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 1',
                                bannerColRight1,
                                1
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 2',
                                bannerColRight2,
                                2
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 3',
                                bannerColRight3,
                                3
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 4',
                                bannerColRight4,
                                4
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 5',
                                bannerColRight5,
                                5
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 6',
                                bannerColRight6,
                                6
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Banner;
