import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {Button} from '@components';
import {Card, Form, Row, Col} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {HTML5BANNER_API, CATEGORY_API} from '@app/utils/apiUrl';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Post, Get} from '@app/utils/httpProvider';
import InputFile from '@app/components/input-file/InputFile';
import {
    acceptZip,
    messageError,
    status200,
    messageToast
} from '@app/utils/define';
import SelectTree from '@app/components/select-tree/SelectTree';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    filePath: yup.string().required(messageError.emptyMessage)
});

const Html5BannerAdd = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const addFunc = useMutation((value) =>
        Post(HTML5BANNER_API.ADD_HTML5BANNER, null, value, false)
    );

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const handleCreate = (values) => {
        const model = {...values};
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        // handle API
        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    setIsLoading(true);
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const listMenu = listCate.data ? listCate.data.data.$values : [];

    return (
        <Card>
            <Card.Header as="h5">Tạo mới file Html 5</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        name: '',
                        filePath: '',
                        isExtracted: false,
                        categoryId: '',
                        width: 0,
                        height: 0
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tên *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tên file"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Danh mục *
                                </Form.Label>
                                <Col sm="10">
                                    <SelectTree
                                        data={listMenu}
                                        showSearchBox
                                        Placeholder="Danh mục"
                                        positionExpand="right"
                                        singleValueSelected={values.id}
                                        onChange={(value) => {
                                            setFieldValue(
                                                'categoryId',
                                                value.id
                                            );
                                        }}
                                        isInvalid={!!errors.categoryId}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.categoryId}
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Chọn file *
                                </Form.Label>
                                <Col sm="10">
                                    <InputFile
                                        multiple
                                        name="filePath"
                                        accept={acceptZip}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                setFieldValue(
                                                    'filePath',
                                                    files[0].filePath,
                                                    true
                                                );
                                            }
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.filePath}
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Độ rộng
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="width"
                                        placeholder="Sắp xếp"
                                        value={values.width}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Độ cao
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="height"
                                        placeholder="Sắp xếp"
                                        value={values.height}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{span: 10, offset: 2}}>
                                    <Button
                                        type="submit"
                                        disabled={isLoading}
                                        theme="primary"
                                    >
                                        Thêm
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <ReactSpinner loadings={isLoading} />
            </Card.Body>
        </Card>
    );
};

export default Html5BannerAdd;
