import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import * as moment from 'moment';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { postPagingAction } from '@store/reducers/post';
import { POST_API, CATEGORY_API, BaseGatewayImage } from '../../utils/apiUrl';
import { Get } from '../../utils/httpProvider';
import { subInput, createUrlWithParam, getInfomationAccountFromToken } from '../../utils/helper';
import { dataTypeCategory, role, postStatus } from '../../utils/define';
import { URI_INTERNAL } from '../../utils/pathLocations';

const ListPost = (props) => {
    const { status } = props;
    const dispatch = useDispatch();
    const listPost = useSelector((state) => state.post.listPost);

    const listCate = useQuery(['cateData'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const getListPost = async () => {
        const modelPost = {
            Status: status,
            PageIndex: 1,
            PageSize: 20
        };
        await dispatch(postPagingAction(modelPost));
    };

    let accountRole = false;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRole = userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher);
    }

    React.useEffect(() => {
        if (listPost) {
            getListPost();
        }
    }, [status]);
    return (
        <div
            style={{
                height: '100%',
                overflow: 'scroll',
                marginTop: 10
            }}
            className={`container-fluid ${listPost && listPost.length === 0 ? 'center-flex' : ''
                }`}
        >
            {listPost != null &&
                listPost !== undefined &&
                listPost.length > 0 ? (
                <div>
                    {listPost.map((item, index) => (
                        <div key={Number(index)} className="row mr-bt-1r">
                            <div className="col-lg-3 col-6">
                                <Image
                                    src={
                                        item.imageThumbnail
                                            ? `${BaseGatewayImage}${item.imageThumbnail}`
                                            : `${BaseGatewayImage}${item.imagePath}`
                                    }
                                    height={130}
                                    width="100%"
                                    // fluid
                                    size
                                    rounded
                                />
                            </div>
                            <div className="col-lg-9 col-6 gx-news-content">
                                <h4 className="gx-news-title mt-0">
                                    {item.title
                                        ? subInput(item.title, 50)
                                        : subInput(item.description, 100)}
                                </h4>
                                <div className="gx-news-brief gx-mb-2">
                                    {subInput($(item.description).text(), 100)}
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-6">
                                            <label className="">
                                                <i className="icon icon-datepicker gx-mr-4 far fa-clock" />
                                                {item.publishTime
                                                    ? moment(
                                                        item.publishTime
                                                    ).format('DD/MM/YYYY')
                                                    : ''}
                                            </label>{' '}
                                            <span
                                                htmlFor="exampleEmail2"
                                                className="ml-2"
                                            >
                                                {listCate && listCate.data
                                                    ? listCate.data.data.$values.find(
                                                        (x) =>
                                                            x.id ===
                                                            item.categoryId
                                                    ).name
                                                    : ''}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}
                                            className="col-lg-6 col-6"
                                        >
                                            <NavLink
                                                to={
                                                    item.status === postStatus.release && !accountRole
                                                        ? "" :
                                                        // item.dataType ===
                                                        //     dataTypeCategory.video
                                                        //     ? createUrlWithParam(
                                                        //         URI_INTERNAL.VIDEO_EDIT_PATH,
                                                        //         {
                                                        //             id: item.id
                                                        //         }
                                                        //     )
                                                        //     : createUrlWithParam(
                                                        //         URI_INTERNAL.POST_EDIT_PATH,
                                                        //         {
                                                        //             id: item.id
                                                        //         }
                                                        //     )
                                                        (() => {
                                                            switch (item.dataType) {
                                                                case dataTypeCategory.video:
                                                                    return createUrlWithParam(
                                                                        URI_INTERNAL.VIDEO_EDIT_PATH,
                                                                        {
                                                                            id: item.id
                                                                        });
                                                                case dataTypeCategory.information:
                                                                    return createUrlWithParam(
                                                                        URI_INTERNAL.POST_EDIT_PATH,
                                                                        {
                                                                            id: item.id
                                                                        });
                                                                case dataTypeCategory.image:
                                                                    return createUrlWithParam(
                                                                        URI_INTERNAL.LIBRARY_IMAGE_EDIT_PATH,
                                                                        {
                                                                            id: item.id
                                                                        });
                                                                default:
                                                                    return null;
                                                            }
                                                        })()
                                                }
                                                exact
                                                className="nav-link"
                                            >
                                                <p className="gx-text-primary">
                                                    Xem thêm
                                                    <i className="icon ml-2 icon-arrow-right fa fa-arrow-right" />
                                                </p>{' '}
                                            </NavLink>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <p className="">
                                            <i className="icon icon-datepicker gx-mr-4 far fa-clock" />
                                            {item.publishTime
                                                ? moment(
                                                      item.publishTime
                                                  ).format('DD/MM/YYYY')
                                                : ''}
                                        </p>{' '}
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="mr-2"
                                        >
                                            Hiển thị :
                                        </label>
                                    </div> */}
                                </div>
                                {/* <div className="col-lg-12">
                                    <div className="col-lg-6 col-6">
                                        <div className="row">
                                            <p className="">
                                                <i className="icon icon-datepicker gx-mr-4 far fa-clock" />
                                                {item.publishTime
                                                    ? moment(
                                                          item.publishTime
                                                      ).format('DD/MM/YYYY')
                                                    : ''}
                                            </p>{' '}
                                            <p className="">
                                                <i className="icon icon-datepicker gx-ml-15 far fa-file-text" />
                                                {listCate && listCate.data
                                                    ? listCate.data.data.$values.find(
                                                          (x) =>
                                                              x.id ===
                                                              item.categoryId
                                                      ).name
                                                    : ''}
                                            </p>{' '}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6 ">
                                        <div className="row jus-content-end gx-mr-4">
                                            <NavLink
                                                onClick={() => {
                                                    alert(
                                                        `${item.id} /${item.slug} / Nếu status là release thì vào trang client , còn lại thì vào trang edit`
                                                    );
                                                }}
                                                to
                                                exact
                                                className="nav-link"
                                            >
                                                <p className="gx-text-primary">
                                                    Xem thêm
                                                    <i className="icon icon-arrow-right fa fa-arrow-right" />
                                                </p>{' '}
                                            </NavLink>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div>
                    <h5 colSpan="12">Không có bài đăng</h5>
                </div>
            )}
        </div>
    );
};

export default ListPost;
