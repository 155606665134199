import React from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {messageError, messageToast, typeError} from '../../utils/define';
import {updateAccountAction, reloadAccount} from '../../store/reducers/account';

const schema = yup.object().shape({
    fullname: yup.string().required(messageError.emptyMessage),
    email: yup
        .string()
        .email(messageError.emailMessage)
        .required(messageError.emptyMessage)
});

const SettingsTab = ({isActive}) => {
    const itemAccount = useSelector((state) => state.account.itemAccount);
    const dispatch = useDispatch();

    const handleUpdate = async (values, actions) => {
        const modelUpdate = {
            data: values,
            id: itemAccount.id
        };

        try {
            const result = await dispatch(updateAccountAction(modelUpdate));
            if (result.payload.errors) {
                if (result.payload.type === typeError.Email) {
                    actions.setErrors({
                        email: result.payload.errors.content[0]
                    });
                }
                // toast.error(result.payload.errors.content[0]);
            }
            if (result.payload === 200) {
                await dispatch(reloadAccount(values));
                toast.success(messageToast.messageToastUpdateSuccess);
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };
    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <Card.Body>
                <Formik
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={(values, actions) =>
                        handleUpdate(values, actions)
                    }
                    initialValues={{
                        fullname: itemAccount ? itemAccount.fullname : '',
                        email: itemAccount ? itemAccount.email : '',
                        roles: itemAccount ? itemAccount.roles.split(', ') : [],
                        isActive: itemAccount ? itemAccount.isActive : false
                    }}
                >
                    {({handleSubmit, handleChange, values, errors}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Họ tên
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Họ tên"
                                        name="fullname"
                                        value={values.fullname}
                                        onChange={handleChange}
                                        isInvalid={!!errors.fullname}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.fullname}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Email
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{span: 10, offset: 2}}>
                                    <Button type="submit" theme="danger">
                                        Cập nhật
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </div>
    );
};

export default SettingsTab;
