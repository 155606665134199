import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { DYNAMICDATA_API } from '@app/utils/apiUrl';
import { DeleteSingle, Get } from '@app/utils/httpProvider';
import { createUrlWithParam } from '@app/utils/helper';
import { URI_INTERNAL } from '@app/utils/pathLocations';
import { objectShowNumber, messageToast } from '@app/utils/define';
import { ConfirmModal } from '@app/modules/modal/ConfirmModal';

const LandingComponent = () => {
    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');

    const deleteFunc = useMutation((value) =>
        DeleteSingle(DYNAMICDATA_API.DELETE, value, null)
    );

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (paramUrl.get('page') != null && parseInt(paramUrl.get('page'), 10) !== page) {
        setPage(parseInt(paramUrl.get('page'), 10));
    }
    if (paramUrl.get('size') != null && parseInt(paramUrl.get('size'), 10) !== pageSize) {
        setPageSize(parseInt(paramUrl.get('size'), 10));
    }

    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const { isLoading, isError, error, data, refetch } = useQuery(['lstComponent', valueSearch, page, pageSize], () =>
        Get(DYNAMICDATA_API.GET_ALL_PAGING, null, { Keyword: valueSearch ? valueSearch : '', PageIndex: page, PageSize: pageSize }));

    useEffect(() => {
        refetch();
    }, [pageSize, page]);

    const replaceHistory = async (Page, Size, Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.LANDING_COMPONENT_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch);
        }
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    const acceptDelete = async () => {
        if (idWantDelete !== '') {
            deleteFunc.mutate(idWantDelete, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        refetch();
                        toast.success(messageToast.messageToastDeleteSuccess);
                        if (data.data.$values.length === 1 && page !== 1) {
                            history.push(
                                createUrlWithParam(URI_INTERNAL.LANDING_COMPONENT_PATH, {
                                    page: page - 1,
                                    size: pageSize,
                                    search: search
                                })
                            );
                        }
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            replaceHistory(page, pageSize, search);
            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách Component</h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label htmlFor="exampleEmail2" className="mr-2" >
                                        Hiển thị :
                                    </label>
                                    <select className="form-control" value={pageSize} onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option key={Number(key)} value={item.value} >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </form>
                                <NavLink className="nav-link-reject nav-link" to={URI_INTERNAL.LANDING_COMPONENT_CREATE_PATH} >
                                    <button type="button" className="btn btn-info" >
                                        Thêm mới
                                    </button>
                                </NavLink>
                            </div>
                            {isLoading ? (<div>Loading...</div>) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting col-sm-1">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Tên
                                                        </th>
                                                        <th className="sorting col-sm-1">
                                                            Sắp xếp
                                                        </th>
                                                        <th className="sorting col-sm-1">
                                                            Hành động
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {data.data.$values != null && data.data.$values !== undefined && data.data.$values.length > 0 ? (
                                                    <tbody>
                                                        {data.data.$values.map((item, index) =>
                                                        (
                                                            <tr key={Number(index)} className="odd">
                                                                <td className="dtr-control sorting_1">
                                                                    {index + 1 + (page - 1) * pageSize}
                                                                </td>
                                                                <td>
                                                                    {item.componentName}
                                                                </td>
                                                                <td>
                                                                    {item.orderNumber}
                                                                </td>
                                                                <td>
                                                                    <ul className="nav">
                                                                        <li className="nav-item">
                                                                            <NavLink to={createUrlWithParam(URI_INTERNAL.LANDING_COMPONENT_EDIT_PATH, { id: item.id })} exact className="nav-link"  >
                                                                                <i title="Sửa" className="nav-icon font-icon fas fa-pen" />
                                                                            </NavLink>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                            <NavLink onClick={() => deleteAction(item.id, item.componentName)} to exact className="nav-link" >
                                                                                <i title="Xóa" className="nav-icon font-icon far fa-trash-alt" />
                                                                            </NavLink>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Tên
                                                        </th>
                                                        <th className="sorting">
                                                            Sắp xếp
                                                        </th>
                                                        <th className="sorting">
                                                            Hành động
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate" >
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination pagination-sm no-margin pull-right"
                                                    activePage={page}
                                                    itemsCountPerPage={Number(pageSize)}
                                                    totalItemsCount={data.totalRecords}
                                                    pageRangeDisplayed={5}
                                                    onChange={(number) => changePage(number)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LandingComponent;