const LOGIN_PATH = '/login';
const FORGOT_PASSWORD_PATH = '/forgot-pass';
const RECOVER_PASSWORD_PATH = '/recover-pass';
const ACCOUNT_PATH = '/account';
const ACCOUNT_PATH_ADD = `${ACCOUNT_PATH}/add`;
const ACCOUNT_PATH_EDIT = `${ACCOUNT_PATH}/edit`;
const LOGIN_HISTORY_PATH = '/login-history';
const STATISTIC_HISTORY_PATH = '/statistic-login-history';

const LANDING_COMPONENT_PATH = '/landing-component';
const LANDING_COMPONENT_CREATE_PATH = '/landing-component/create';
const LANDING_COMPONENT_EDIT_PATH = '/landing-component/edit';

const NEWSCRAWLER_PATH = '/newscrawler';
const NEWSCRAWLER_EDIT_PATH = '/newscrawler/edit';

const SLIDE_PATH = '/slide-image';
const SLIDE_CREATE_PATH = '/slide-image/create';
const SLIDE_EDIT_PATH = '/slide-image/edit';

const BANNER_PATH = '/banner';
const BANNER_CREATE_PATH = '/banner/create';
const BANNER_EDIT_PATH = '/banner/edit';
const EMAILCONFIG_PATH = '/emailconfig';
const EMAILCONFIG_CREATE_PATH = '/emailconfig/create';
const EMAILCONFIG_EDIT_PATH = '/emailconfig/edit';

const PARTNER_PATH = '/partner';
const PARTNER_CREATE_PATH = '/partner/create';
const PARTNER_EDIT_PATH = '/partner/edit';

const CATEGORY_PATH = '/category';
const CATEGORY_CREATE_PATH = '/category/create';
const CATEGORY_EDIT_PATH = '/category/edit';

const POST_PATH = '/post';
const POST_CREATE_PATH = '/post/create';
const POST_EDIT_PATH = '/post/edit';

const LOG_PATH = '/log';

const VIDEO_PATH = '/video';
const VIDEO_CREATE_PATH = '/video/create';
const VIDEO_EDIT_PATH = '/video/edit';

const LIBRARY_IMAGE_PATH = '/image';
const LIBRARY_IMAGE_CREATE_PATH = '/image/create';
const LIBRARY_IMAGE_EDIT_PATH = '/image/edit';

const HTML5BANNER_PATH = '/html5Banner';
const HTML5BANNER_CREATE_PATH = '/html5Banner/create';
const HTML5BANNER_EDIT_PATH = '/html5Banner/edit';

const REPORT_POST_PATH = '/report-post';
const REPORT_POST_SYNTHETIC_PATH = '/report-post-synthentic';
const REPORT_POST_EXCEL_PATH = '/report-post-excel';
const REPORT_POST_EXCEL_QUANTITY_CREATE_BY =
    '/report-post-excel-quantity-createby';
const REPORT_POST_EXCEL_CREATE_BY = '/report-post-excel-createby';

const REPORT_POST_WEBANALYSIS_PATH = '/report-post-web-analysis';
const REPORT_POST_BY_DATETYPE = '/report-post-by-datetype';
const REPORT_POST_VIEW_DETAIL = '/report-post-view-detail';
const REPORT_POST_PUBLISHTIME_DATATYPE_PATH =
    '/report-post-publish-time-data-type';
const REPORT_ADVERTISING_ANALYSIS_PATH =
    '/advertising-analysis';
const REPORT_COUNT_VISIT_DETAIL =
    '/report-count-visit-detail';

export const URI_INTERNAL = {
    REPORT_ADVERTISING_ANALYSIS_PATH,
    REPORT_POST_VIEW_DETAIL,
    REPORT_POST_BY_DATETYPE,
    REPORT_POST_EXCEL_CREATE_BY,
    REPORT_POST_EXCEL_QUANTITY_CREATE_BY,
    REPORT_POST_SYNTHETIC_PATH,
    REPORT_POST_PATH,
    RECOVER_PASSWORD_PATH,
    FORGOT_PASSWORD_PATH,
    ACCOUNT_PATH_EDIT,
    ACCOUNT_PATH_ADD,
    LOGIN_PATH,
    ACCOUNT_PATH,
    LOGIN_HISTORY_PATH,
    LANDING_COMPONENT_PATH,
    LANDING_COMPONENT_CREATE_PATH,
    LANDING_COMPONENT_EDIT_PATH,
    NEWSCRAWLER_PATH,
    NEWSCRAWLER_EDIT_PATH,
    SLIDE_PATH,
    SLIDE_CREATE_PATH,
    SLIDE_EDIT_PATH,
    BANNER_PATH,
    BANNER_CREATE_PATH,
    BANNER_EDIT_PATH,
    EMAILCONFIG_PATH,
    EMAILCONFIG_CREATE_PATH,
    EMAILCONFIG_EDIT_PATH,
    PARTNER_PATH,
    PARTNER_CREATE_PATH,
    PARTNER_EDIT_PATH,
    CATEGORY_PATH,
    CATEGORY_CREATE_PATH,
    CATEGORY_EDIT_PATH,
    POST_PATH,
    POST_CREATE_PATH,
    POST_EDIT_PATH,
    STATISTIC_HISTORY_PATH,
    LOG_PATH,
    VIDEO_PATH,
    VIDEO_EDIT_PATH,
    VIDEO_CREATE_PATH,
    REPORT_POST_EXCEL_PATH,
    REPORT_POST_WEBANALYSIS_PATH,
    REPORT_POST_PUBLISHTIME_DATATYPE_PATH,
    HTML5BANNER_PATH,
    HTML5BANNER_CREATE_PATH,
    HTML5BANNER_EDIT_PATH,
    REPORT_COUNT_VISIT_DETAIL,
    LIBRARY_IMAGE_PATH,
    LIBRARY_IMAGE_CREATE_PATH,
    LIBRARY_IMAGE_EDIT_PATH,
};
