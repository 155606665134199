import React, {useState} from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import {Get, Put} from '@app/utils/httpProvider';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import SelectTree from '@app/components/select-tree/SelectTree';
import {NIL as NIL_UUID} from 'uuid';
import {
    messageError,
    messageToast,
    acceptZip,
    status200,
} from '@app/utils/define';
import {
    BaseGatewayImage,
    HTML5BANNER_API,
    CATEGORY_API
} from '@app/utils/apiUrl';
import ReactSpinner from '@app/components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    filePath: yup.string().required(messageError.emptyMessage)
});

const Html5BannerEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    // const [publishTime, setPublishTime] = useState(new Date());
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const id = paramUrl.get('id');

    const bannerItem = useQuery([id], () =>
        Get(HTML5BANNER_API.GET_ITEM_HTML5BANNER, id)
    );

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const editFunc = useMutation((value) =>
        Put(HTML5BANNER_API.UPDATE_HTML5BANNER, value.id, value.data)
    );

    const handleEdit = async (values) => {
        const model = {...values};
        // model.imagePath = postItem.data.imagePath;
        // model.imageThumbnail = postItem.data.imageThumbnail;
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;

        const modelUpdate = {
            data: model,
            id: id
        };
        editFunc.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    const response = res.json().then((data) => {
                        queryClient.setQueryData(id, data);
                    });
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const isLoading = bannerItem.isLoading || listCate.isLoading;
    const isError = bannerItem.isError || listCate.isError;
    const error = bannerItem.isError ? bannerItem.error : listCate.error;

    let menuData = [];
    if (!listCate.isLoading && !listCate.isError) {
        menuData = listCate.data ? listCate.data.data.$values : [];
    }

    return (
        <Card>
            <Card.Header as="h5">Sửa file Html 5</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : bannerItem.data == null ? (
                    <div>Tin tức không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            name:
                                bannerItem.data && bannerItem.data.name
                                    ? bannerItem.data.name
                                    : '',
                            filePath:
                                bannerItem.data && bannerItem.data.filePath
                                    ? bannerItem.data.filePath
                                    : '',
                            width:
                                bannerItem.data && bannerItem.data.width
                                    ? bannerItem.data.width
                                    : 0,
                            height:
                                bannerItem.data && bannerItem.data.height
                                    ? bannerItem.data.height
                                    : 0,
                            categoryId:
                                bannerItem.data.categoryId === NIL_UUID
                                    ? 'null'
                                    : bannerItem.data.categoryId
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tên *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tên file"
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục *
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={menuData}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={menuData.find(
                                                (x) =>
                                                    x.id === values.categoryId
                                            )}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Chọn file *
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            multiple
                                            values={  values.filePath ? [ `${BaseGatewayImage}${values.filePath}` ] : [] }
                                            name="filePath"
                                            accept={acceptZip}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    if (files[0].filePath) {
                                                        setFieldValue(
                                                            'filePath',
                                                            files[0].filePath,
                                                            true
                                                        ); 
                                                    } else {
                                                        const newPath = files[0].url.replace(BaseGatewayImage, '');
                                                        setFieldValue(
                                                            'filePath',
                                                            newPath,
                                                            true
                                                        );
                                                    }
                                                } else {
                                                    setFieldValue(
                                                        'filePath',
                                                        '',
                                                        true
                                                    ); 
                                                }
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.filePath}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Độ rộng
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="width"
                                            placeholder="Sắp xếp"
                                            value={values.width}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Độ cao
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="height"
                                            placeholder="Sắp xếp"
                                            value={values.height}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{span: 10, offset: 2}}>
                                        <Button type="submit" theme="primary">
                                            Sửa
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingEdit} />
            </Card.Body>
        </Card>
    );
};

export default Html5BannerEdit;
