import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {LOGIN_HISTORY_CONST} from '../constants';
import {Get} from '../../utils/httpProvider';
import {LOGIN_HISTORY_API} from '../../utils/apiUrl';

export const loginHistoryPagingAction = createAsyncThunk(
    LOGIN_HISTORY_CONST.GET_PAGING,
    async (model) => {
        return Get(LOGIN_HISTORY_API.GET_PAGING_LOGIN, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const loginHistoryStatisticAction = createAsyncThunk(
    LOGIN_HISTORY_CONST.GET_STATISTIC,
    async (model) => {
        return Get(LOGIN_HISTORY_API.STATISTIC_LOGIN, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
const initialState = {
    listLoginHistory: [],
    totalLoginHistory: 0,
    statisticLoginHistory: []
};
export const loginHistorySlice = createSlice({
    name: LOGIN_HISTORY_CONST.NAME_SLICE,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(loginHistoryPagingAction.fulfilled, (state, action) => {
                if (action.payload && action.payload !== undefined) {
                    state.listLoginHistory = action.payload.data
                        ? action.payload.data.$values
                        : [];
                    state.totalLoginHistory = action.payload.totalRecords;
                }
            })
            .addCase(loginHistoryStatisticAction.fulfilled, (state, action) => {
                state.statisticLoginHistory = action.payload
                    ? action.payload.$values
                    : [];
            });
    }
});
export default loginHistorySlice.reducer;
