import React, {useState} from 'react';
import {useMutation} from 'react-query';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {SLIDE_API} from '@app/utils/apiUrl';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Post} from '@app/utils/httpProvider';
import InputFile from '@app/components/input-file/InputFile';
import {
    acceptImage,
    messageError,
    status200,
    messageToast
} from '@app/utils/define';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    images: yup.array().min(1, messageError.emptyMessage)
});

const SlideImageAdd = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const addFunc = useMutation((value) =>
        Post(SLIDE_API.CREATE_SLIDE, null, value, false)
    );

    const handleCreate = (values) => {
        // handle API
        addFunc.mutate(values, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    setIsLoading(true);
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <Card>
            <Card.Header as="h5">Tạo mới slide ảnh</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        name: '',
                        images: [],
                        imageThumbs: [],
                        orderNumber: 0
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tên slide *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tên slide"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Danh sách ảnh *
                                </Form.Label>
                                <Col sm="10">
                                    <InputFile
                                        multiple
                                        accept={acceptImage}
                                        onChange={(files) => {
                                            const listImagePath = [];
                                            const listImageThumb = [];

                                            files.forEach((file) => {
                                                listImagePath.push(
                                                    file.filePath
                                                );

                                                listImageThumb.push(
                                                    file.thumbPath
                                                );
                                            });
                                            setFieldValue(
                                                'images',
                                                listImagePath,
                                                true
                                            );
                                            setFieldValue(
                                                'imageThumbs',
                                                listImageThumb,
                                                true
                                            );
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.images}
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Sắp xếp
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="orderNumber"
                                        placeholder="Sắp xếp"
                                        value={values.orderNumber}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Label className="col-sm-2 col-form-label">
                                    Hoạt động
                                </Form.Label>
                                <Col
                                    sm="10"
                                    className="icheck-primary d-inline"
                                >
                                    <Form.Control
                                        type="checkbox"
                                        id="checkboxPrimary1"
                                    />
                                    <Form.Label htmlFor="checkboxPrimary1">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                            disabled={isLoading}
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <ReactSpinner loadings={isLoading} />
            </Card.Body>
        </Card>
    );
};

export default SlideImageAdd;
