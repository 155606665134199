import React from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import {Get, Put} from '@app/utils/httpProvider';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import {
    messageError,
    messageToast,
    acceptImage,
    status200
} from '../../utils/define';
import {convertStringToArrayImages} from '../../utils/helper';
import {BaseGatewayImage, SLIDE_API} from '../../utils/apiUrl';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    imageThumbs: yup.array().min(1, messageError.emptyMessage)
});

const SlideImageEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const id = paramUrl.get('id');

    const {isLoading, isError, error, data} = useQuery([id], () =>
        Get(SLIDE_API.GET_ITEM, id)
    );

    const editFunc = useMutation((value) =>
        Put(SLIDE_API.UPDATE_SLIDE, value.id, value.data)
    );

    const handleEdit = async (values) => {
        const model = values;

        if (model.images === undefined) {
            model.images = data.images.split(',');
            model.imageThumbs = data.imageThumbs.split(',');
        }

        const modelUpdate = {
            data: model,
            id: id
        };
        editFunc.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    if (model.images !== undefined) {
                        model.images = model.images.join(',');
                        model.imageThumbs = model.imageThumbs.join(',');
                    }
                    queryClient.setQueryData(id, model);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin slide ảnh</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : data == null ? (
                    <div>Slide ảnh không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{ 
                            name: data && data.name ? data.name : '',
                            orderNumber:  data && data.orderNumber ? data.orderNumber : 0,
                            imageThumbs:  data && data.imageThumbs ? convertStringToArrayImages( data.imageThumbs ): []
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tên *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh sách ảnh *
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            multiple
                                            values={ values.imageThumbs ? values.imageThumbs : [] }
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    let imageThumbs = files.map(
                                                        (x) => x.url
                                                    );
                                                    let images = imageThumbs;
                                                    imageThumbs =
                                                        imageThumbs.map((x) =>
                                                            x.replace(
                                                                BaseGatewayImage,
                                                                ''
                                                            )
                                                        );
                                                    images = images.map((x) => {
                                                        x = x.replace(
                                                            BaseGatewayImage,
                                                            ''
                                                        );
                                                        x = x.replace(
                                                            '_thumb',
                                                            ''
                                                        );
                                                        return x;
                                                    });

                                                    setFieldValue(
                                                        'imageThumbs',
                                                        imageThumbs,
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'images',
                                                        images,
                                                        true
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        'imageThumbs',
                                                        [],
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'images',
                                                        [],
                                                        true
                                                    );
                                                }
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbs}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Sắp xếp
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="orderNumber"
                                            placeholder="Sắp xếp"
                                            value={values.orderNumber}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
        </Card>
    );
};

export default SlideImageEdit;
