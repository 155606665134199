import React, { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { Image } from 'react-bootstrap';
import { BaseGatewayImage, CATEGORY_API, POST_API } from '@app/utils/apiUrl';
import { DeleteSingle, Get, Post } from '@app/utils/httpProvider';
import * as moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
    createUrlWithParam,
    formatDateTimeCrawler,
    dateTimeToStringRequest,
    getInfomationAccountFromToken,
    subInput
} from '@app/utils/helper';
import { URI_INTERNAL } from '@app/utils/pathLocations';
import { ConfirmModal } from '@app/modules/modal/ConfirmModal';
import {
    objectShowNumber,
    messageToast,
    dataTypeCategory,
    postStatus,
    objectStatusPost,
    rangeDate,
    role
} from '@app/utils/define';

const Video = () => {
    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState(postStatus.all);
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });
    const [category, setCategory] = useState("");
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');

    const addMutation = useMutation((value) =>
        DeleteSingle(POST_API.DELETE_POST, value, null)
    );
    const changeStatusFunc = useMutation((model) =>
        Post(POST_API.CHANGE_STATUS_POST_API, model.id, { status: model.status })
    );

    let accountRole = false;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        accountRole = userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher);
    }

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }
    if (paramUrl.get('status') != null && paramUrl.get('status') !== 'undefined' && paramUrl.get('status') !== status) {
        setStatus(paramUrl.get('status'));
    }
    if (paramUrl.get('cate') != null && paramUrl.get('cate') !== category) {
        setCategory(paramUrl.get('cate'));
    }
    // if (paramUrl.get('to') != null && paramUrl.get('to') !== toDate) {
    //     setToDate(paramUrl.get('to'));
    // }
    // if (paramUrl.get('from') != null && paramUrl.get('from') !== fromDate) {
    //     setFromDate(paramUrl.get('from'));
    // }

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['cateData', valueSearch, pageSize, page, status, dates],
        () => {
            const model = {
                Keyword: valueSearch ? valueSearch : '',
                pageIndex: page,
                pageSize: pageSize,
                status: status,
                dataType: dataTypeCategory.video,
                CateId: category
            };
            if (dates.startDate) {
                model.fromDate = dateTimeToStringRequest(
                    dates.startDate.toDate()
                );
            }
            if (dates.endDate) {
                model.toDate = dateTimeToStringRequest(dates.endDate.toDate());
            }
            return Get(POST_API.GET_PAGING_POST, null, model);
        }
    );

    const replaceHistory = async (Page, Size, Search, status, cate, from, to) => {
        const model = {
            page: Page,
            size: Size,
            search: Search,
            status: status,
            cate: cate
        };

        if (from !== null && from !== '') {
            model.from = moment(from).format('yyyy/MM/DD'); // dateTimeToString(from);
        }
        if (to !== null && to !== '') {
            model.to = moment(to).format('yyyy/MM/DD'); // dateTimeToString(to);
        }
        history.replace(createUrlWithParam(URI_INTERNAL.VIDEO_PATH, model));
        // chỗ này để gọi đến API lấy dữ liệu
    };

    // lấy danh sách các danh mục
    const dataCategory = useQuery(
        ['cateData'],
        () =>
            Get(CATEGORY_API.GET_ALL_CATEGORY, null)
    );
    let objectCategory = null;
    if (dataCategory && dataCategory.data && dataCategory.data.data) {
        const lstCateVideo = dataCategory.data.data.$values.filter(
            (x) => x.dataType === dataTypeCategory.video
        )
        if (lstCateVideo) {
            objectCategory = lstCateVideo.map(elem => (
                {
                    label: elem.name,
                    value: elem.id
                }
            ));
        }
    }
    React.useEffect(() => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.VIDEO_PATH, {
                page: page,
                size: pageSize,
                search: ''
            })
        );
    }, []);

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch, status, category);
        }
    }, [valueSearch]);

    React.useEffect(() => {
        refetch();
    }, [page, pageSize, category, dates]);

    const changePage = (number) => {
        replaceHistory(number, pageSize, search, status, category);
    };

    const onChangeShowNumber = (e) => {
        replaceHistory(page, e.target.value, search, status, category);
    };

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    const acceptDelete = async () => {
        if (idWantDelete !== '') {
            addMutation.mutate(idWantDelete, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        replaceHistory(page, pageSize, valueSearch);
                        refetch();
                        toast.success(messageToast.messageToastDeleteSuccess);
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        // replaceHistory(
        //     page,
        //     pageSize,
        //     search,
        //     status,
        //     picker.startDate,
        //     picker.endDate
        // );
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };
    const onChangeStatus = (e, id) => {
        const model = {
            status: e.target.value,
            id: id
        }
        changeStatusFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status === 201 || res.status === 200) {
                    refetch();
                    toast.success(messageToast.messageToastUpdateSuccess);
                } else {
                    toast.error(messageToast.messageToastUpdateError);
                }
            },
            onError: (res) => {
                toast.error(messageToast.messageToastTryCatch);
            }
        });
        replaceHistory(page, pageSize, search, status, category);
    }
    const getTitleCategory = (idCate) => {
        if (dataCategory && dataCategory.data && dataCategory.data.data) {
            const itemCate = dataCategory.data.data.$values.find(x => x.id === idCate);
            if (itemCate) {
                return itemCate.name;
            }
        }
        return null;
    }
    function validURL(str) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return !!pattern.test(str);
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách video</h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label htmlFor="exampleEmail2" className>
                                        Hiển thị :
                                    </label>
                                    <select
                                        className="form-control ml-2"
                                        value={pageSize}
                                        onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3"
                                    >
                                        Trạng thái :
                                    </label>
                                    <select
                                        className="form-control ml-2"
                                        value={status}
                                        onChange={(e) =>
                                            replaceHistory(
                                                page,
                                                pageSize,
                                                search,
                                                e.target.value,
                                                category
                                            )
                                        }
                                    >
                                        {objectStatusPost.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3"
                                    >
                                        Danh mục :
                                    </label>
                                    <select
                                        className="form-control ml-2"
                                        value={category}
                                        onChange={(e) =>
                                            replaceHistory(
                                                page,
                                                pageSize,
                                                search,
                                                status,
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option
                                            value=""
                                        >
                                            Chọn danh mục
                                        </option>
                                        {objectCategory ? objectCategory.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </option>
                                        )) : null}
                                    </select>
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3 mr-2"
                                    >
                                        Thời gian đăng tin :
                                    </label>
                                    <DateRangePicker
                                        onApply={handleApply}
                                        onCancel={handleCancel}
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                cancelLabel: 'Clear'
                                            },
                                            ranges: rangeDate
                                        }}
                                    >
                                        <input
                                            type="text"
                                            className="form-control"
                                        />
                                    </DateRangePicker>
                                </form>
                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to={URI_INTERNAL.VIDEO_CREATE_PATH}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Thêm mới
                                    </button>
                                </NavLink>
                            </div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table responsive bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Tiêu đề
                                                        </th>
                                                        <th className="sorting">
                                                            Ảnh đại diện
                                                        </th>
                                                        <th className="sorting">
                                                            Tiêu điểm
                                                        </th>
                                                        <th className="sorting">
                                                            Danh mục
                                                        </th>
                                                        <th className="sorting">
                                                            Link video
                                                        </th>
                                                        <th className="sorting">
                                                            Lượt xem
                                                        </th>
                                                        <th className="sorting">
                                                            Tác giả
                                                        </th>
                                                        <th className="sorting">
                                                            Nguời xuất bản
                                                        </th>
                                                        <th className="sorting">
                                                            Thời gian xuất bản
                                                        </th>
                                                        <th className="sorting">
                                                            Trạng thái
                                                        </th>
                                                        <th className="sorting">
                                                            Hành động
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {data &&
                                                    data.data != null &&
                                                    data.data !== undefined &&
                                                    data.data.$values.length > 0 ? (
                                                    <tbody>
                                                        {data.data.$values.map(
                                                            (item, index) => (
                                                                <tr
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="odd"
                                                                >
                                                                    <td className="dtr-control sorting_1">
                                                                        {index +
                                                                            1 +
                                                                            (page -
                                                                                1) *
                                                                            pageSize}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.title ? subInput(item.title, 50) : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {item.imageThumbnail ? (
                                                                            <Image
                                                                                src={` ${BaseGatewayImage}${item.imageThumbnail}`}
                                                                                rounded
                                                                            />) : null}
                                                                    </td>
                                                                    <td className="dtr-control sorting_1">
                                                                        <div
                                                                            className={`${item.isFocus
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                                } `}
                                                                            title={`${item.isFocus
                                                                                ? 'Là tiêu điểm'
                                                                                : 'Không phải tiêu điểm'
                                                                                }`}
                                                                            role="presentation"
                                                                            onClick={
                                                                                () => { }
                                                                                // setIsMenu(
                                                                                //     row.node
                                                                                // )
                                                                            }
                                                                        >
                                                                            <i className="fas fa-circle" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {getTitleCategory(item.categoryId)}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            !validURL(item.videoLink) ? BaseGatewayImage + item.videoLink : item.videoLink
                                                                            // item.videoLink ? item.videoLink : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.views ? item.views : 0
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.author ? item.author : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.publishBy ? item.publishBy : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {formatDateTimeCrawler(
                                                                            item.publishTime
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            accountRole ? <select
                                                                                className="form-control"
                                                                                value={item.status}
                                                                                onChange={(e) => onChangeStatus(e, item.id)}
                                                                            >
                                                                                {objectStatusPost.filter(x => x.value !== postStatus.all).map((item, key) => (
                                                                                    <option
                                                                                        key={Number(key)}
                                                                                        value={item.value}
                                                                                    >
                                                                                        {item.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select> :
                                                                                (
                                                                                    objectStatusPost.find(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.value ===
                                                                                            item.status
                                                                                    )
                                                                                        .label
                                                                                )
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <ul className="nav">
                                                                            <li className="nav-item">
                                                                                {
                                                                                    item.status === postStatus.release && !accountRole
                                                                                        ?
                                                                                        null : <NavLink
                                                                                            to={createUrlWithParam(
                                                                                                URI_INTERNAL.VIDEO_EDIT_PATH,
                                                                                                {
                                                                                                    id: item.id
                                                                                                }
                                                                                            )}
                                                                                            exact
                                                                                            className="nav-link"
                                                                                        >
                                                                                            <i
                                                                                                title="Sửa"
                                                                                                className="nav-icon font-icon fas fa-pen"
                                                                                            />
                                                                                        </NavLink>
                                                                                }

                                                                            </li>
                                                                            <li className="nav-item">
                                                                                <NavLink
                                                                                    onClick={() =>
                                                                                        deleteAction(
                                                                                            item.id,
                                                                                            item.name
                                                                                        )
                                                                                    }
                                                                                    to
                                                                                    exact
                                                                                    className="nav-link"
                                                                                >
                                                                                    <i
                                                                                        title="Xóa"
                                                                                        className="nav-icon font-icon far fa-trash-alt"
                                                                                    />
                                                                                </NavLink>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="example2_paginate"
                                            >
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination pagination-sm no-margin pull-right"
                                                    activePage={page}
                                                    itemsCountPerPage={Number(
                                                        pageSize
                                                    )}
                                                    totalItemsCount={
                                                        data.totalRecords
                                                    }
                                                    pageRangeDisplayed={5}
                                                    onChange={(number) =>
                                                        changePage(number)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Video;
