import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from 'react-js-pagination';
import { useMutation, useQuery } from 'react-query';
import { Post, Get } from '@app/utils/httpProvider';
import { ACCOUNT_API, CATEGORY_API, WEB_ANALYSIS } from '@app/utils/apiUrl';
import Table from 'react-bootstrap/Table';
import * as moment from 'moment';
import SelectTree from '@app/components/select-tree/SelectTree';
import { URI_INTERNAL } from '@app/utils/pathLocations';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { getStatisticPostSynthticAction } from '../../../store/reducers/reportPost';
import {
    objectStatusPost,
    objectDataTypeCategory,
    rangeDate,
    postStatus,
    messageToast,
    status200,
    dataTypeCategory
} from '../../../utils/define';

import { dateTimeToStringRequest, exportExcel, createUrlWithParam, subInput, formatDateTimeCrawler } from '../../../utils/helper';

const ReportViewByPost = ({ isActive }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [status, setStatus] = useState(postStatus.all);
    // loại bài viết
    const [valueDateType, setValueDateType] = useState(dataTypeCategory.all);
    // danh mục
    const [valueCate, setValueCate] = useState('');
    // ngày xuất bản
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });
    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );
    const listAccount = useQuery(['getAllAccount'], () =>
        Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, {
            PageIndex: 1,
            PageSize: 100
        })
    );

    let lstObjectCategory;
    let lstObjectAccount;
    if (
        listCate.data &&
        listCate.data.data &&
        listCate.data.data.$values.length > 0
    ) {
        lstObjectCategory = listCate.data.data.$values.map((elem) => ({
            label: elem.name,
            value: elem.id
        }));
    }

    if (
        listAccount.data &&
        listAccount.data.data &&
        listAccount.data.data.$values.length > 0
    ) {
        lstObjectAccount = listAccount.data.data.$values.map((elem) => ({
            label: elem.fullname,
            value: elem.username
        }));
    }

    // người tạo
    const [author, setAuthor] = useState('');

    // useEffect(() => {
    //     const modelPost = {
    //         dates: dates,
    //         author: author,
    //         status: status,
    //         valueCate: valueCate,
    //         valueDateType: valueDateType
    //     };
    // }, [dates, author, status, valueCate, valueDateType]);

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['countVisitDetailData', page, pageSize, status, valueDateType, dates, author],
        () => {
            const model = {
                pageIndex: page,
                pageSize: pageSize,
                postStatus: status,
                dataType: valueDateType,
                categoryId: valueCate,
                author: author
            };
            if (dates.startDate) {
                model.fromDate = dateTimeToStringRequest(
                    dates.startDate.toDate()
                );
            }
            if (dates.endDate) {
                model.toDate = dateTimeToStringRequest(dates.endDate.toDate());
            }
            return Get(WEB_ANALYSIS.COUNT_VISITS_DETAIL, null, model);
        }
    );

    const countTotal = useQuery(['countTotalByConditionData'], () => {
        const model = {
            pageIndex: page,
            pageSize: pageSize,
            postStatus: status,
            dataType: valueDateType,
            categoryId: valueCate,
            author: author
        };
        if (dates.startDate) {
            model.fromDate = dateTimeToStringRequest(
                dates.startDate.toDate()
            );
        }
        if (dates.endDate) {
            model.toDate = dateTimeToStringRequest(dates.endDate.toDate());
        }
        return Get(WEB_ANALYSIS.COUNT_VISITS_DETAIL_TOTAL, null, model);
    }
    );

    // const replaceHistory = async (Page, Size, dataType, status, cate, from, to) => {
    //     const model = {
    //         page: Page,
    //         size: Size,
    //         status: status,
    //         cate: cate
    //     };

    //     if (from !== null && from !== '') {
    //         model.from = moment(from).format('yyyy/MM/DD'); // dateTimeToString(from);
    //     }
    //     if (to !== null && to !== '') {
    //         model.to = moment(to).format('yyyy/MM/DD'); // dateTimeToString(to);
    //     }
    //     history.replace(createUrlWithParam(URI_INTERNAL.REPORT_COUNT_VISIT_DETAIL, model));
    // };

    React.useEffect(() => {
        refetch();
        countTotal.refetch();
    }, [page, pageSize, valueCate, valueDateType, status, dates]);

    // useEffect(() => {
    //     const modelPost = {
    //         dataTypeCategory: valueDateType,
    //         fromDate: dates.startDate
    //             ? dateTimeToStringRequest(dates.startDate.toDate())
    //             : '',
    //         toDate: dates.endDate
    //             ? dateTimeToStringRequest(dates.endDate.toDate())
    //             : ''
    //     };
    //     dispatch(getStatisticPostSynthticAction(modelPost));
    // }, [valueDateType, dates]);

    const onChangeRepostType = (e) => {
        setValueDateType(e.target.value);
    };

    const handleApply = (event, picker) => {
        setDates({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
        picker.element.val(
            `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
                'DD/MM/YYYY'
            )}`
        );
    };
    const handleCancel = (event, picker) => {
        setDates({
            startDate: null,
            endDate: null
        });
        picker.element.val('');
    };
    const changePage = (number) => {
        setPage(number);
    };

    const getTitleCategory = (idCate) => {
        if (listCate && listCate.data && listCate.data.data) {
            const itemCate = listCate.data.data.$values.find(x => x.id === idCate);
            if (itemCate) {
                return itemCate.name;
            }
        }
        return null;
    }

    const generateFunc = useMutation((value) =>
        Post(WEB_ANALYSIS.GENERATE_REPORT_POST_COUNT_VISITS_DETAIL, null, value, false)
    );

    const handleCreate = async () => {
        const model = {
            postStatus: status === postStatus.all ? null : status,
            dataType: valueDateType === dataTypeCategory.all ? null : valueDateType,
            categoryId: valueCate,
            author: author
        };

        if (dates.startDate) {
            model.fromDate = dateTimeToStringRequest(
                dates.startDate.toDate()
            );
        }
        if (dates.endDate) {
            model.toDate = dateTimeToStringRequest(dates.endDate.toDate());
        }

        generateFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastReportExcelError);
                } else {
                    res.blob().then((data) => {
                        saveAs(data, `baocao_luotxemchitiet_theobaiviet_${Date.now()}`);
                    });
                    toast.success(messageToast.messageToastReportExcelSuccess);
                    // history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h1 className="card-title">
                                    {' '}
                                    Báo cáo lượt xem theo bài viết
                                </h1>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <form className="form-inline">
                                        <label htmlFor="exampleEmail2">
                                            Người tạo:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={author}
                                            onChange={(e) =>
                                                setAuthor(e.target.value)
                                            }
                                        >
                                            <option value="">Tất cả</option>
                                            {lstObjectAccount &&
                                                lstObjectAccount.length > 0
                                                ? lstObjectAccount.map(
                                                    (item, key) => (
                                                        <option
                                                            key={Number(key)}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </option>
                                                    )
                                                )
                                                : null}
                                        </select>

                                        <label
                                            htmlFor="exampleEmail2"
                                            className="ml-3 mr-2"
                                        >
                                            Trạng thái kiểm duyệt:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={status}
                                            onChange={(e) =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            {/* <option value="">Trạng thái kiểm duyệt</option> */}
                                            {objectStatusPost.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="ml-3 mr-2"
                                        >
                                            Thời gian xuất bản :
                                        </label>
                                        <DateRangePicker
                                            onApply={handleApply}
                                            onCancel={handleCancel}
                                            initialSettings={{
                                                autoUpdateInput: false,
                                                locale: {
                                                    cancelLabel: 'Clear'
                                                },
                                                ranges: rangeDate
                                            }}
                                        >
                                            <input
                                                type="text"
                                                className="form-control"
                                            />
                                        </DateRangePicker>
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="ml-3 mr-2"
                                        >
                                            Loại bài viết:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={valueDateType}
                                            onChange={(e) =>
                                                onChangeRepostType(e)
                                            }
                                        >
                                            <option value="">Tất cả</option>
                                            {objectDataTypeCategory.map(
                                                (item, key) => (
                                                    <option
                                                        key={Number(key)}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="ml-3 mr-2"
                                        >
                                            Danh mục:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={valueCate}
                                            onChange={(e) =>
                                                setValueCate(e.target.value)
                                            }
                                        >
                                            <option value="">Tất cả</option>
                                            {lstObjectCategory &&
                                                lstObjectCategory.length > 0
                                                ? lstObjectCategory.map(
                                                    (item, key) => (
                                                        <option
                                                            key={Number(key)}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </option>
                                                    )
                                                )
                                                : null}
                                        </select>
                                    </form>
                                </div>
                                <NavLink
                                        className="nav-link-reject nav-link"
                                        to
                                        onClick={() => handleCreate()}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-info"
                                        >
                                            Xuất file Excel
                                        </button>
                                    </NavLink>
                                {isLoading ? (
                                    <div>Loading...</div>
                                ) : isError ? (
                                    <div>
                                        An error has occurred: ${error.message}
                                    </div>
                                ) : (
                                    <div
                                        id="example2_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4"
                                    >
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Table>
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="sorting">
                                                                STT
                                                            </th>
                                                            <th className="sorting">
                                                                Tiêu đề
                                                            </th>
                                                            <th className="sorting">
                                                                Danh mục
                                                            </th>
                                                            <th className="sorting">
                                                                Ngày xuất bản
                                                            </th>
                                                            <th className="sorting">
                                                                Lượt xem
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {data &&
                                                        data.data != null &&
                                                        data.data !== undefined &&
                                                        data.data.$values.length > 0 ? (
                                                        <tbody>
                                                            {data.data.$values.map(
                                                                (item, index) => (
                                                                    <tr role="row" key={Number(
                                                                        index
                                                                    )}
                                                                        className="odd">
                                                                        <td className="sorting">
                                                                            {index +
                                                                                1 +
                                                                                (page -
                                                                                    1) *
                                                                                pageSize}
                                                                        </td>
                                                                        <td className="sorting">
                                                                            {
                                                                                item.title ? subInput(item.title, 50) : ""
                                                                            }
                                                                        </td>
                                                                        <td className="sorting">
                                                                            {getTitleCategory(item.categoryId)}
                                                                        </td>
                                                                        <td className="sorting">
                                                                            {formatDateTimeCrawler(
                                                                                item.publishTime
                                                                            )}
                                                                        </td>
                                                                        <td className="sorting">
                                                                            {
                                                                                item.views ? item.views : 0
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            <tr role="row">
                                                                <td
                                                                    colSpan="4"
                                                                    className="sorting"
                                                                >
                                                                    Tổng tất cả lượt xem
                                                                </td>
                                                                <td>{countTotal.data ? countTotal.data : 0}</td>
                                                            </tr>
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            <tr className="txt-center">
                                                                <td colSpan="12">
                                                                    Không có dữ liệu
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers"
                                                    id="example2_paginate"
                                                >
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        innerClass="pagination pagination-sm no-margin pull-right"
                                                        activePage={page}
                                                        itemsCountPerPage={Number(
                                                            pageSize
                                                        )}
                                                        // totalItemsCount={
                                                        //     data.totalRecords
                                                        // }
                                                        totalItemsCount={data ? data.totalRecords : null}
                                                        pageRangeDisplayed={5}
                                                        onChange={(number) =>
                                                            changePage(number)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportViewByPost;
