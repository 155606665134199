import React from 'react';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Select from 'react-select';
import InputFile from '@app/components/input-file/InputFile';
import Editor from '@app/components/editor/Editor';
import {Post} from '@app/utils/httpProvider';
import {CATEGORY_API} from '@app/utils/apiUrl';
import {useMutation} from 'react-query';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    styleSelectDefault,
    styleSelectError,
    status200
} from '../../utils/define';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    dataType: yup.object().required(messageError.emptyMessage)
    // parentId: yup.string().required(messageError.emptyMessage)
});

const CategoryAdd = () => {
    const history = useHistory();

    // const {isLoading, isError, error, data} = useQuery(['cateData'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY, null)
    // );

    const addFunc = useMutation((value) =>
        Post(CATEGORY_API.ADD_CATEGORY, null, value, false)
    );

    const handleCreate = async (values) => {
        const model = {...values};
        // model.parentId = values.parentId === 'null' ? '' : values.parentId;
        // model.parentId = '';
        model.dataType = values.dataType.value;

        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    // if (isLoading) return 'Loading...';

    // if (isError) return `An error has occurred: ${error.message}`;

    // let listMenu = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];

    // listMenu = listMenu.concat(data.data.$values);

    return (
        <Card>
            <Card.Header as="h5">Thêm danh mục</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        name: '',
                        parentId: '',
                        description: '',
                        keywords: '',
                        ogTitle: '',
                        ogDescription: '',
                        imagePath: '',
                        imageThumbnail: '',
                        dataType: objectDataTypeCategory[0],
                        orderNumber: 0,
                        isMenu: false
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tên *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tên"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                Danh mục cha
                                </Form.Label>
                                <Col sm="10">
                                    <SelectTree
                                        data={listMenu}
                                        showSearchBox
                                        Placeholder="Danh mục"
                                        positionExpand="right"
                                        singleValueSelected={values.id}
                                        onChange={(value) => {
                                            setFieldValue('parentId', value.id);
                                        }}
                                        isInvalid={!!errors.parentId}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.parentId}
                                    </div>
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tóm tắt
                                </Form.Label>
                                <Col sm="10">
                                    <Editor
                                        name="description"
                                        value={values.description}
                                        onChange={(value) => {
                                            setFieldValue('description', value);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Ảnh
                                </Form.Label>
                                <Col sm="10">
                                    <InputFile
                                        accept={acceptImage}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                setFieldValue(
                                                    'imagePath',
                                                    files[0].filePath,
                                                    true
                                                );
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files[0].thumbPath,
                                                    true
                                                );
                                            }
                                        }}
                                    />

                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.parentId}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Là Menu
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary1"
                                        type="checkbox"
                                        placeholder="Là Menu"
                                        name="isMenu"
                                        checked={values.isMenu}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary1">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Kiểu dữ liệu
                                </Form.Label>
                                <Col sm="10">
                                    <Select
                                        name="dataType"
                                        id="dataType"
                                        placeholder="Chọn kiểu dữ liệu"
                                        value={values.dataType}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: 'dataType',
                                                    value: selectedOption
                                                }
                                            };
                                            handleChange(event);
                                        }}
                                        options={objectDataTypeCategory}
                                        styles={
                                            errors.dataType !== undefined
                                                ? styleSelectError
                                                : styleSelectDefault
                                        }
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.dataType}
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Sắp xếp
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="orderNumber"
                                        placeholder="Sắp xếp"
                                        value={values.orderNumber}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Từ khóa Meta
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Từ khóa"
                                        type="text"
                                        name="keywords"
                                        value={values.keywords}
                                        onChange={handleChange}
                                        isInvalid={!!errors.keywords}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.keywords}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tiêu đề Og Meta
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tiêu đề Og"
                                        type="text"
                                        name="ogTitle"
                                        value={values.ogTitle}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ogTitle}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ogTitle}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mô tả Og Meta
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Mô tả Og"
                                        type="text"
                                        name="ogDescription"
                                        value={values.ogDescription}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ogDescription}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ogDescription}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                {/* <ReactSpinner loadings={isLoading} /> */}
            </Card.Body>
        </Card>
    );
};

export default CategoryAdd;
