import React from 'react';
import Select from 'react-select';
import {Button} from '@components';
import {Card, Form, Row, Col, ButtonGroup} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
// import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
    messageError,
    messageToast,
    objectRoles,
    typeError,
    styleSelectError,
    styleSelectDefault
} from '../../utils/define';
import {addAccountAction} from '../../store/reducers/account';

const schema = yup.object().shape({
    username: yup.string().required(messageError.emptyMessage),
    fullname: yup.string().required(messageError.emptyMessage),
    email: yup
        .string()
        .email(messageError.emailMessage)
        .required(messageError.emptyMessage),
    password: yup
        .string()
        .min(6, messageError.minLength6Message)
        .max(30, messageError.maxLength30Message)
        .required(messageError.emptyMessage),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], messageError.likePassword)
        .required(messageError.emptyMessage),
    roles: yup.array().min(1, messageError.emptyMessage)
});

const AccountAdd = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCreate = async (values, actions) => {
        const model = {...values};
        const roleAccount =
            model.roles.length > 0 ? model.roles.map((x) => x.value) : [];
        model.roles = roleAccount;
        try {
            const result = await dispatch(addAccountAction(model));
            if (result.payload.errors) {
                if (result.payload.type === typeError.UserName) {
                    actions.setErrors({
                        username: result.payload.errors.content[0]
                    });
                }
                if (result.payload.type === typeError.Email) {
                    actions.setErrors({
                        email: result.payload.errors.content[0]
                    });
                }
            }
            if (result.payload === 200) {
                toast.success(messageToast.messageToastCreateSuccess);
                history.goBack();
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm tài khoản</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values, actions) =>
                        handleCreate(values, actions)
                    }
                    initialValues={{
                        username: '',
                        fullname: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                        phoneNumber: '',
                        pseudonym: '',
                        isActive: false,
                        roles: []
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tài khoản *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tài khoản"
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Họ tên *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Họ tên"
                                        name="fullname"
                                        value={values.fullname}
                                        onChange={handleChange}
                                        isInvalid={!!errors.fullname}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.fullname}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Email *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Số điện thoại
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Số điện thoại"
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        // isInvalid={!!errors.email}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Bút danh
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Bút danh"
                                        name="pseudonym"
                                        value={values.pseudonym}
                                        onChange={handleChange}
                                        // isInvalid={!!errors.email}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Danh sách quyền *
                                </Form.Label>
                                {console.log(
                                    errors != null && errors.roles !== undefined
                                )}

                                <Col sm="10">
                                    <Select
                                        isMulti
                                        name="roles"
                                        id="roles"
                                        placeholder="Chọn quyền cho tài khoản"
                                        value={values.roles}
                                        onChange={(selectedOption) => {
                                            setFieldValue(
                                                'roles',
                                                selectedOption,
                                                true
                                            );
                                        }}
                                        options={objectRoles}
                                        styles={
                                            errors.roles !== undefined
                                                ? styleSelectError
                                                : styleSelectDefault
                                        }
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.roles}
                                    </div>

                                    {/* <Select
                                        name="filters"
                                        placeholder="Quyền"
                                        value={multiValue}
                                        options={objectRoles}
                                        onChange={handleMultiChange}
                                        isMulti
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Danh sách quyền không được trống
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mật khẩu *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        placeholder="Mật khẩu"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={!!errors.password}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Xác nhận mật khẩu *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        placeholder="Xác nhận mật khẩu"
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Trạng thái kích hoạt
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary1"
                                        type="checkbox"
                                        placeholder="Trạng thái"
                                        name="isActive"
                                        checked={values.isActive}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary1">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default AccountAdd;
