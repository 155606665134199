import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from '@components';
import { Card, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import DynamicInput from '@pages/landing/DynamicInput';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    dataTypeComponent
} from '@app/utils/define';
import { DYNAMICDATA_API } from '@app/utils/apiUrl';
import { Get, Post } from '@app/utils/httpProvider';

const schema = yup.object().shape({
    componentName: yup.string().required(messageError.emptyMessage),
    ikey: yup.string().required(messageError.emptyMessage),
    dataType: yup.object().required(messageError.emptyMessage),
    orderNumber: yup.number(),
    // value: yup.string().required(messageError.emptyMessage)
});

const LandingComponentAdd = () => {
    const [dataTypeState, setDataTypeState] = useState(dataTypeComponent[0].value);
    const [dynamicData, setDynamicData] = useState("");

    const { t } = useTranslation();
    const history = useHistory();

    const handleDataTypeChange = (event) => {
        setDataTypeState(event.target.value.value);
    };

    const handleChangeDynamicData = (data) => {
        setDynamicData(data);
    }

    const addDynamicData = useMutation((model) =>
        Post(DYNAMICDATA_API.CREATE, null, model, false)
    );

    const handleCreate = async (values) => {
        const tempModel = { ...values };
        tempModel.dataType = values.dataType.value;
        const model = {
            componentName: tempModel.componentName,
            key: tempModel.ikey,
            type: tempModel.dataType,
            value: dynamicData,
            orderNumber: tempModel.orderNumber
        }
        console.log("TCL: handleCreate -> dynamicData", dynamicData);
        console.log("TCL: handleCreate -> model", model);

        addDynamicData.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== 200 && res.status !== 201) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm mới</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        componentName: '',
                        ikey: '',
                        orderNumber: 0,
                        dataType: dataTypeComponent[0],
                        value: ''
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    {t('landing.label.componentName')}
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder=""
                                        type="text"
                                        name="componentName"
                                        value={values.componentName}
                                        onChange={handleChange}
                                        isInvalid={!!errors.componentName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.componentName}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    {t('landing.label.key')}
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder=""
                                        type="text"
                                        name="ikey"
                                        value={values.ikey}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ikey}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ikey}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    {t('landing.label.orderNumber')}
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder=""
                                        type="number"
                                        name="orderNumber"
                                        value={values.orderNumber}
                                        onChange={handleChange}
                                        isInvalid={!!errors.orderNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.orderNumber}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    {t('landing.label.dataType')}
                                </Form.Label>
                                <Col sm="10">
                                    <Select
                                        name="dataType"
                                        id="dataType"
                                        placeholder="Kiểu dữ liệu"
                                        value={values.dataType}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: 'dataType',
                                                    value: selectedOption
                                                }
                                            };
                                            handleChange(event);
                                            handleDataTypeChange(event);
                                        }}
                                        options={dataTypeComponent}
                                    />
                                    <div style={{ display: 'block', color: '#dc3545' }} className="invalid-feedback" >
                                        {errors.dataType}
                                    </div>
                                </Col>
                            </Form.Group>
                            <DynamicInput dataType={dataTypeState} handleChangeDynamicData={handleChangeDynamicData} />

                            <Form.Group as={Row} className="offset-sm-2 col-sm-1">
                                <Button type="submit" theme="primary">
                                    {t('button.save')}
                                </Button>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default LandingComponentAdd;
