import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ReportViewByCate from './ReportViewByCate';
import ReportViewByPost from './ReportViewByPost';

const ReportViewDetail = () => {
    const [activeTab, setActiveTab] = useState('TAB_BY_POST');
    const [t] = useTranslation();

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header p-2">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${
                                                    activeTab === 'TAB_BY_POST'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    toggle('TAB_BY_POST')
                                                }
                                            >
                                                Báo cáo theo bài viết
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                className={`nav-link ${
                                                    activeTab === 'TAB_BY_CATE'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    toggle('TAB_BY_CATE')
                                                }
                                            >
                                                Báo cáo theo danh mục
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <ReportViewByPost
                                            isActive={
                                                activeTab === 'TAB_BY_POST'
                                            }
                                        />
                                        <ReportViewByCate
                                            isActive={
                                                activeTab === 'TAB_BY_CATE'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ReportViewDetail;
