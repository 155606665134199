import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REPORT_ADVERTISING_ANALYSIS_CONST } from '../constants';
import { Get } from '../../utils/httpProvider';
import { ADVERTISING_ANALYSIS_API } from '../../utils/apiUrl';

export const getCountAdvertisingAnalysisByConditionAction = createAsyncThunk(
    REPORT_ADVERTISING_ANALYSIS_CONST.GET_COUNT_ADVERTISING_ANALYSIS,
    async (model) => {
        return Get(ADVERTISING_ANALYSIS_API.COUNT_ADVERTISING_ANALYSIS, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

const initialState = {
    listCountAdversingClick: [],
};
export const reportAdvertisingAnalysisSlice = createSlice({
    name: REPORT_ADVERTISING_ANALYSIS_CONST.NAME_SLICE,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(
                getCountAdvertisingAnalysisByConditionAction.fulfilled,
                (state, action) => {
                    console.log("action",action)
                    if (action.payload && action.payload.$values) {
                        state.listCountAdversingClick = action.payload.$values;
                    }
                }
            );
    }
});

export default reportAdvertisingAnalysisSlice.reducer;
