import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-xcode";
import InputFile from '@app/components/input-file/InputFile';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { acceptImage } from '@app/utils/define';
import { isValidUrl } from '@app/utils/helper';
import { BaseGatewayImage } from '@app/utils/apiUrl';

const DynamicInputEdit = (props) => {
    const { t } = useTranslation();
    const { dataType, handleChangeDynamicData, initValue } = props;
    const [dynamicData, setDynamicData] = useState("");

    const onChangeData = (data) => {
        setDynamicData(data);
        handleChangeDynamicData(data);
    }

    useEffect(() => {
        setDynamicData(initValue ? initValue : '');
    }, [initValue]);

    const onUploadImageSuccess = (files) => {
        if (files.length > 0) {
            const imgModel = {
                imagePath: files[0].filePath,
                imageThumbPath: files[0].thumbPath
            }
            onChangeData(JSON.stringify(imgModel));
        }
    }

    switch (dataType) {
        case "html":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.html')}
                    </Form.Label>
                    <Col sm="10">
                        <HtmlInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        case "text":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.text')}
                    </Form.Label>
                    <Col sm="10">
                        <TextInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        case "image":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.image')}
                    </Form.Label>
                    <Col sm="10">
                        <InputFile name="imageThumbnail"
                            accept={acceptImage}
                            onChange={onUploadImageSuccess}
                        />
                    </Col>
                </Form.Group>
            )
        case "video":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.video')}
                    </Form.Label>
                    <Col sm="10">
                        <VideoInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        case "model":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.advanced')}
                    </Form.Label>
                    <Col sm="10">
                        <AdvancedInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        default:
            return (<div />)
    };


}
const HtmlInput = (prop) => {
    const { handleChange, initValue } = prop;
    const [iHtml, setIHtml] = useState(initValue);
    const onChange = (newValue) => {
        handleChange(newValue);
        setIHtml(newValue);
    }

    return (
        <AceEditor
            placeholder=""
            mode="c_cpp"
            theme="xcode"
            name="blah2"
            fontSize={14}
            value={iHtml}
            className="code-editor"
            onChange={e => onChange(e)}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
            }} />
    );
}

const TextInput = (prop) => {
    const { handleChange, initValue } = prop;
    return (
        <textarea className="form-control" rows={2} placeholder="" onChange={(e) => handleChange(e.target.value)} value={initValue} />
    );
}

const VideoInput = (prop) => {
    const { handleChange, initValue } = prop;
    return (
        <input
            type="text"
            className="form-control"
            id="idVideoInput"
            placeholder=''
            value={initValue}
            onChange={(e) => handleChange(e.target.value)}
        />
    );
}

const AdvancedInput = (prop) => {
    const { handleChange, initValue } = prop;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('T-1');
    const [values, setValues] = useState([{ key: `T-1`, firstText: '', secondText: '', css: '', webLink: '', mediaLink: '', imageThumbTabPath: '', imageTabPath: '' }]);
    const [tabs, setTabs] = useState([{ index: 0, key: 'T-1', text: "Tab 1" }]);
    const [dataModel, setDataModel] = useState({
        name: '',
        title: '',
        description: '',
        imageLink: '',
        lstAdvancedModel: '',
    });

    const [imageThumbPath, setImageThumbPath] = useState("");
    const [imagePath, setImagePath] = useState("");

    const onUploadImageSuccess = (files) => {
        if (files.length > 0) {
            setImagePath(files[0].filePath);
            setImageThumbPath(files[0].thumbPath);
        }
    }

    useEffect(() => {
        const initModel = JSON.parse(initValue);
        setDataModel({ ...dataModel, name: initModel.name, title: initModel.title, description: initModel.description, imageLink: initModel.imageLink, lstAdvancedModel: JSON.stringify(initModel.lstAdvancedModel) });

        if (initModel && initModel.lstAdvancedModel && initModel.lstAdvancedModel.length > 0) {
            let lstTab = [];
            let lstValue = []
            initModel.lstAdvancedModel.forEach((item, index) => {
                lstTab = [...lstTab, { index: index, key: `T-${index + 1}`, text: `Tab ${index + 1}` }]
                lstValue = [...lstValue, { key: `T-${index + 1}`, firstText: item.firstText, secondText: item.secondText, css: item.css, webLink: item.webLink, mediaLink: item.mediaLink, imageThumbTabPath: '', imageTabPath: '' }]

            });
            setTabs(lstTab);
            setValues(lstValue);
        }
    }, [])


    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        handleChange(JSON.stringify(dataModel))
    }, [dataModel])

    const handleAddObject = () => {
        let maxIndex = Math.max(...tabs.map(o => o.index));
        ++maxIndex;
        setTabs([...tabs, { index: maxIndex, key: `T- ${maxIndex}`, text: `Tab ${maxIndex + 1}` }]);
        setValues([...values, { key: `T- ${maxIndex}`, firstText: '', secondText: '', css: '', webLink: '', mediaLink: '', imageThumbTabPath: '', imageTabPath: '' }]);
    }

    const changeValue = (key, property, event) => {
        if (values.some(item => key === item.key)) {
            const valuesTemp = [...values];
            const item = valuesTemp.find((element) => { return element.key === key; });
            switch (property) {
                case 'firstText':
                    item.firstText = event.target.value;
                    break;
                case 'secondText':
                    item.secondText = event.target.value;
                    break;
                case 'css':
                    item.css = event.target.value;
                    break;
                case 'webLink':
                    item.webLink = event.target.value;
                    break;
                case 'mediaLink':
                    item.mediaLink = event.target.value;
                    break;
                case 'image':
                    if (event[0] && event[0].filePath && event[0].thumbPath) {
                        item.imageTabPath = event[0].filePath;
                        item.imageThumbTabPath = event[0].thumbPath;
                    }
                    break;
                default:
                    break;
            }
            setValues(valuesTemp);
            setDataModel({ ...dataModel, lstAdvancedModel: valuesTemp.map((element) => { return { firstText: element.firstText, secondText: element.secondText, css: element.css, mediaLink: element.mediaLink, webLink: element.webLink } }) });
        }
    }

    const handleChangeText = (event, property) => {
        switch (property) {
            case 'name':
                setDataModel({ ...dataModel, name: event.target.value });
                break;
            case 'title':
                setDataModel({ ...dataModel, title: event.target.value });
                break;
            case 'description':
                setDataModel({ ...dataModel, description: event.target.value });
                break;
            case 'imageLink':
                setDataModel({ ...dataModel, imageLink: event.target.value });
                break;
            default:
                break;
        }
    }

    return (
        <Card>
            <Card.Header as="h5"> {t('landing.label.advanced')}</Card.Header>
            <Card.Body>
                <Form noValidate>
                    <Form.Group as={Row} className="form-group row">
                        <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('landing.label.name')}</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="idInputName" placeholder='' onChange={e => handleChangeText(e, 'name')} value={dataModel.name} />
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="form-group row">
                        <label htmlFor="inputTitle" className="col-sm-2 col-form-label">{t('landing.label.title')}</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="idInputTitle" onChange={e => handleChangeText(e, 'title')} value={dataModel.title} />
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="form-group row">
                        <label htmlFor="inputTitle" className="col-sm-2 col-form-label">{t('landing.label.description')}</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" rows={2} onChange={e => handleChangeText(e, 'description')} value={dataModel.description} />
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label className="col-sm-2 col-form-label">
                            Lấy đường dẫn ảnh
                        </Form.Label>
                        <Col sm="10">
                            <InputFile name="imageThumbnail"
                                accept={acceptImage}
                                values={[isValidUrl(dataModel.imageLink) ? dataModel.imageLink : `${BaseGatewayImage}${dataModel.imageLink}`]}
                                onChange={onUploadImageSuccess}
                            />
                            <div
                                style={{
                                    display: 'block',
                                    color: '#000000'
                                }}
                                className="invalid-feedback"
                            >
                                {imageThumbPath ? imageThumbPath : ""}
                            </div>
                            <div
                                style={{
                                    display: 'block',
                                    color: '#000000'
                                }}
                                className="invalid-feedback"
                            >
                                {imagePath ? imagePath : ""}
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="form-group row">
                        <label htmlFor="inputImageLink" className="col-sm-2 col-form-label">{t('landing.label.imageLink')}</label>
                        <div className="col-sm-10">
                            <input className="form-control" onChange={e => handleChangeText(e, 'imageLink')} value={dataModel.imageLink} />
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="form-group row">
                        <div className="card card-primary card-outline card-outline-tabs col-md-12">
                            <div className="card-header p-0 border-bottom-0">
                                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                    {tabs.map((tab) => (
                                        <li className="nav-item" key={tab.key}>
                                            <a onClick={() => toggle(tab.key)} className={`nav-link ${activeTab === tab.key ? 'active' : ''}`} id="custom-tabs-first-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-first" aria-selected="true">{tab.text}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-four-tabContent">
                                    {tabs.map((tab) => (
                                        <TabContent isActive={activeTab === tab.key} ikey={tab.key} handleChange={changeValue} key={tab.key} value={values.find(x => x.key === tab.key)} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Card.Body>
            <Card.Footer>
                <button type="button" className="btn btn-info float-right" onClick={() => handleAddObject()}>{t('landing.button.addObject')} </button>
            </Card.Footer>
        </Card>
    );
}

const TabContent = (props) => {
    const { isActive, ikey, handleChange, value } = props;
    const { t } = useTranslation();
    const [lstImage, setLstIamge] = useState([]);

    useEffect(() => {
        if (value.mediaLink) {
            setLstIamge([isValidUrl(value.mediaLink) ? value.mediaLink : `${BaseGatewayImage}${value.mediaLink}`]);
        }
    }, [value.mediaLink])
    return (
        <div className={`tab-pane fade ${isActive ? 'show active' : ''}`} id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
            <Form noValidate>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('landing.label.firstText')}</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "firstText", e)} value={value.firstText} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('landing.label.secondText')}</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "secondText", e)} value={value.secondText} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('landing.label.css')}</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "css", e)} value={value.css} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('landing.label.webLink')}</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "webLink", e)} value={value.webLink} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        Lấy đường dẫn ảnh
                    </Form.Label>
                    <Col sm="10">
                        <InputFile name="imageThumbnail"
                            accept={acceptImage}
                            values={lstImage}
                            onChange={(e) => handleChange(ikey, "image", e)}
                        />
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {value.imageThumbTabPath}
                        </div>
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {value.imageTabPath}
                        </div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('landing.label.meidaLink')}</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "mediaLink", e)} value={value.mediaLink} />
                    </div>
                </Form.Group>
            </Form>
        </div>
    );
}

export default DynamicInputEdit;
