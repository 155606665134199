import React, {useState, useRef} from 'react';
import {Form} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {NIL as NIL_UUID} from 'uuid';
// import { consoleLog, validInput } from '../../utils/helper';
const SelectTree = (props) => {
    const refTreeMenu = useRef();
    const refTreeInput = useRef();
    const {
        data, // [{id,name,parentId}]
        actions, // [{title,text,action,Icon,href,color}]
        onChange,
        positionExpand, // right,left,
        multiSelect,
        Placeholder,
        searchKeyword,
        showSearchBox, // Hiển thị ô tìm kiếm
        singleValueSelected, // chỉ chọn 1 (ko có checkbox)
        multiValueSelected, // trong trường hợp chọn nhiều (có checkbox)
        isInvalid
    } = props;

    const [listData, setListData] = useState([]);
    const [listDataSearch, setDataSearch] = useState([]);
    const [valueSelect, setValueSelect] = useState('');
    const [listSelected, setListSelected] = useState([]);

    const unCheck = 'unCheck';
    const checked = 'checked';
    const checking = 'checking';

    const addParentToTree = (parentId, listSearch) => {
        if (parentId !== undefined) {
            const node = listDataSearch.find((x) => x.id === parentId);
            if (node) {
                node.expand = true;
                if (!listSearch.some((x) => x.id === parentId)) {
                    listSearch.push(node);
                }
                listSearch = addParentToTree(node.parentId, listSearch);
            }
        }

        return listSearch;
    };

    const handleSelectParent = (parentId, listDataNew) => {
        const children = listDataNew.filter((x) => x.parentId === parentId);
        const childrenChecked = children.filter(
            (x) => x.checked === checked || x.checked === checking
        );
        const parent = listDataNew.find((x) => x.id === parentId);
        if (parent && !parent.isGroup) {
            parent.checked =
                children && childrenChecked.length > 0
                    ? children.length === childrenChecked.length
                        ? checked
                        : checking
                    : unCheck;
            if (parent.parentId !== '') {
                listDataNew = handleSelectParent(parent.parentId, listDataNew);
            }
        }

        return listDataNew;
    };

    const handleSearchOnTree = (value, listSearch = listDataSearch) => {
        if (listSearch.length > 0) {
            let listDataNew;

            if (value.length > 0) {
                listDataNew = listSearch.filter((item) =>
                    item.name.toLowerCase().includes(value.toLowerCase())
                );
                listDataNew.forEach((element) => {
                    listDataNew = addParentToTree(
                        element.parentId,
                        listDataNew
                    );
                });
            } else {
                listDataNew = listSearch;
            }
            setListData(listDataNew);
        }
    };

    React.useEffect(() => {
        let dataMap = [];
        if (listData.length === 0) {
            dataMap = data.map((item) => ({
                ...item,
                expand: false,
                checked: unCheck
            }));
        } else {
            data.forEach((element) => {
                const itemTree = listData.find((x) => x.id === element.id);
                dataMap.push({
                    ...element,
                    expand: itemTree ? itemTree.expand : false,
                    checked: itemTree ? itemTree.checked : unCheck
                });
            });
        }

        const listSelectedNew = [];
        if (multiValueSelected && multiValueSelected.length > 0) {
            multiValueSelected.forEach((value) => {
                const itemSelected = dataMap.find((x) => x.id === value);
                if (itemSelected) {
                    itemSelected.checked = checked;
                    if (itemSelected.parentId !== '') {
                        dataMap = handleSelectParent(
                            itemSelected.parentId,
                            dataMap
                        );
                    }
                    listSelectedNew.push(itemSelected);
                }
            });
        }

        setListData(dataMap);
        setDataSearch(dataMap);
        handleSearchOnTree(searchKeyword, dataMap);
        setValueSelect(
            singleValueSelected && singleValueSelected !== ''
                ? singleValueSelected
                : valueSelect
        );

        setListSelected(
            listSelectedNew.length > 0 ? listSelectedNew : listSelected
        );
    }, [data, searchKeyword, singleValueSelected, multiValueSelected]);

    const isDataNone = listData.some((x) => x.id === -1);
    let listTree = [];
    const genTree = (items, id = NIL_UUID, levelTree = 1, link = 'parentId') =>
        items
            .filter((item) => item[link] === id)
            .map((item) => ({
                ...item,
                level: levelTree,
                children: genTree(items, item.id, levelTree + 1)
            }));
    listTree = genTree(listData);

    const handleExpandTree = (item) => {
        const listDataNew = [...listData];
        listDataNew.map((x) => {
            if (x.id === item.id) {
                x.expand = !x.expand;
            }
            return x;
        });
        setListData(listDataNew);
    };

    function handleOutsideClick(e) {
        if (refTreeMenu.current) {
            if (refTreeInput.current.contains(e.target)) {
                if (
                    e.target.tagName.includes('path') ||
                    e.target.tagName.includes('svg') ||
                    e.target.tagName.includes('INPUT')
                ) {
                    return;
                }
            }
            if (refTreeMenu.current.contains(e.target)) {
                return;
            }
            document.removeEventListener('click', handleOutsideClick, false);
            refTreeMenu.current.classList.toggle('show');
        }
    }

    function handleOpenBoxTree() {
        if (!refTreeMenu.current.classList.value.includes('show')) {
            document.addEventListener('click', handleOutsideClick, false);
            refTreeMenu.current.classList.toggle('show');
        }
    }

    const handleSelectChildren = (isChecked, parentId, listDataNew) => {
        listDataNew.forEach((item) => {
            if (item.parentId === parentId) {
                item.checked = isChecked;
                item.expand = true;
                listDataNew = handleSelectChildren(
                    isChecked,
                    item.id,
                    listDataNew
                );
            }
        });
        return listDataNew;
    };

    const handleSelectMulti = (e, item) => {
        if (
            !multiSelect ||
            e.target.tagName.includes('path') ||
            e.target.tagName.includes('svg')
        ) {
            return;
        }

        if (item.isGroup) {
            return;
        }

        let dataChange = null;

        let listDataNew = [...listData];
        const statusCheck =
            item.checked === checking || item.checked === unCheck
                ? checked
                : unCheck;

        listDataNew.map((x) => {
            if (x.id === item.id) {
                x.checked =
                    x.checked === checking || x.checked === unCheck
                        ? checked
                        : unCheck;
                x.expand = true;
            }
            return x;
        });
        listDataNew = handleSelectChildren(statusCheck, item.id, listDataNew);
        if (item.parentId !== '') {
            listDataNew = handleSelectParent(item.parentId, listDataNew);
        }
        dataChange = listDataNew.filter(
            (x) => x.checked === checked || x.checked === checking
        );

        setListData(listDataNew);
        setListSelected(dataChange);
        onChange(dataChange);
    };

    const handleSelect = (e, item) => {
        if (
            e.target.tagName.includes('path') ||
            e.target.tagName.includes('svg') ||
            e.target.tagName.includes('SPAN') ||
            e.target.tagName.includes('I')
        ) {
            return;
        }
        setValueSelect(item);

        onChange(item);
        if (refTreeMenu.current.classList.value.includes('show')) {
            document.removeEventListener('click', handleOutsideClick, false);
            refTreeMenu.current.classList.toggle('show');
        }
    };

    const handleSearchChange = (e) => {
        handleSearchOnTree(e.target.value);
    };

    const handleRemoveItem = (item) => {
        const dataChange = listSelected.filter((x) => x.id !== item.id);
        const listDataNew = [...listData];
        listDataNew.find((x) => {
            if (x.id === item.id) {
                x.checked = x.checked === checked ? unCheck : checked;
            }
            return x;
        });
        setListData(listDataNew);
        setListSelected(dataChange);
        onChange(dataChange);
    };

    const renderItemTree = (items, expand = true) => {
        return items
            ? items.map((item) => (
                  <div key={item.id}>
                      <div
                          key={item.id}
                          className={`tree_item_children ${
                              expand && 'tree_item_active'
                          }`}
                      >
                          <div
                              className={`tree_item ${
                                  !multiSelect && 'cursor-pointer'
                              }`}
                              style={{paddingLeft: 10 + (item.level - 1) * 20}}
                              role="presentation"
                              onClick={(e) => handleSelect(e, item)}
                          >
                              {item.children.length > 0 &&
                              positionExpand === 'left' ? (
                                  <span
                                      style={{padding: 5}}
                                      className="expand"
                                      role="presentation"
                                      onClick={() => handleExpandTree(item)}
                                  >
                                      <i
                                          className="fas fa-caret-up"
                                          style={{
                                              display: item.expand
                                                  ? 'block'
                                                  : 'none'
                                          }}
                                      />
                                      <i
                                          className="fas fa-caret-down"
                                          style={{
                                              display: item.expand
                                                  ? 'none'
                                                  : 'block'
                                          }}
                                      />
                                  </span>
                              ) : null}

                              <label
                                  className={`container_check ${
                                      !multiSelect && 'container_check_hide'
                                  }`}
                              >
                                  <input
                                      type="checkbox"
                                      // type={multiSelect ? 'checkbox' : 'text'}
                                      checked={
                                          item.checked === checked ||
                                          item.checked === checking
                                      }
                                      onChange={(e) =>
                                          handleSelectMulti(e, item)
                                      }
                                  />
                                  {item.name}

                                  <span
                                      className={`checkmark ${item.checked} ${
                                          item.checked === checking &&
                                          'checkmark_parent'
                                      }`}
                                  />
                              </label>

                              {item.children.length > 0 &&
                              positionExpand === 'right' ? (
                                  <span
                                      style={{padding: 5}}
                                      className="expand"
                                      role="presentation"
                                      onClick={() => handleExpandTree(item)}
                                  >
                                      <i
                                          className="fas fa-caret-up"
                                          style={{
                                              display: item.expand
                                                  ? 'block'
                                                  : 'none'
                                          }}
                                      />
                                      <i
                                          className="fas fa-caret-down"
                                          style={{
                                              display: item.expand
                                                  ? 'none'
                                                  : 'block'
                                          }}
                                      />
                                  </span>
                              ) : null}
                              <div className="tree_item_action">
                                  {actions.map((action) => (
                                      <NavLink
                                          key={action.title}
                                          style={{padding: 0}}
                                          onClick={() => action.action(item)}
                                      >
                                          <action.Icon
                                              title={action.title}
                                              color={action.color}
                                              className="table-action-control"
                                          />
                                      </NavLink>
                                  ))}
                              </div>
                          </div>
                          {renderItemTree(item.children, item.expand)}
                      </div>
                  </div>
              ))
            : null;
    };

    return (
        <>
            <div style={{position: 'relative'}} ref={refTreeInput}>
                <span
                    className={` select2 select2-container select2-container--default select2-container--below ${
                        listSelected &&
                        listSelected.length > 0 &&
                        'select2-container--focus'
                    }`}
                    role="presentation"
                    dir="ltr"
                    onClick={() => handleOpenBoxTree()}
                    style={{width: '100%'}}
                >
                    <span className="selection">
                        <span
                            className={`select2-selection select2-selection--multiple form-control ${
                                isInvalid && 'is-invalid'
                            }`}
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={-1}
                        >
                            <ul className="select2-selection__rendered">
                                {listSelected &&
                                listSelected.length > 0 &&
                                listSelected.length < 10 ? (
                                    listSelected.map((item) => (
                                        <li
                                            key={item.id}
                                            className="select2-selection__choice"
                                            title={item}
                                        >
                                            {item.name}
                                        </li>
                                    ))
                                ) : listSelected && listSelected.length > 10 ? (
                                    <li className="select2-selection__choice">
                                        {listSelected.length} selected
                                    </li>
                                ) : null}
                                <li className="select2-search select2-search--inline">
                                    <Form.Control
                                        value={
                                            multiSelect ? '' : valueSelect.name
                                        }
                                        placeholder={Placeholder}
                                        className="select2-search__field"
                                        type="text"
                                        onChange={() => {}}
                                        tabIndex={0}
                                        readonly
                                    />
                                </li>
                            </ul>
                        </span>
                    </span>
                </span>

                <div
                    className="dropdown-menu"
                    style={{padding: 0}}
                    ref={refTreeMenu}
                >
                    {showSearchBox && (
                        <div style={{padding: 10}}>
                            <Form.Control
                                placeholder="Tìm kiếm ..."
                                type="text"
                                onChange={(e) => handleSearchChange(e)}
                            />
                        </div>
                    )}

                    {renderItemTree(listTree)}
                </div>
            </div>
        </>
    );
};

SelectTree.prototype = {
    actions: PropTypes.array,
    positionExpand: PropTypes.string, // right,left,
    multiSelect: PropTypes.bool,
    showSearchBox: PropTypes.bool,
    Placeholder: PropTypes.string,
    searchKeyword: PropTypes.string,
    data: PropTypes.array, // [{id,name,parentId}]
    onChange: PropTypes.func,
    singleValueSelected: PropTypes.string,
    multiValueSelected: PropTypes.array,
    EnableValidate: PropTypes.bool,
    ErrorMessage: PropTypes.string,
    ForceValidate: PropTypes.bool,
    isInvalid: PropTypes.bool
};

SelectTree.defaultProps = {
    actions: [], // [{title,text,action,Icon,href,color}]
    positionExpand: 'left', // right,left,
    multiSelect: false,
    showSearchBox: false,
    Placeholder: '',
    searchKeyword: '',
    data: [], // [{id,name,parentId}]
    onChange: () => {},
    singleValueSelected: '',
    multiValueSelected: [],
    EnableValidate: false,
    ErrorMessage: '',
    ForceValidate: false,
    isInvalid: false
};

export default SelectTree;
