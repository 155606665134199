import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {BANNER_CONST} from '../constants';
import {Get, Post, Put, DeleteSingle} from '../../utils/httpProvider';
import {BANNER_API} from '../../utils/apiUrl';

export const bannerPagingAction = createAsyncThunk(
    BANNER_CONST.GET_PAGING,
    async (model) => {
        return Get(BANNER_API.GET_PAGING_API, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const addBannerAction = createAsyncThunk(
    BANNER_CONST.ADD,
    async (model) => {
        return Post(BANNER_API.CREATE_BANNER_API, null, model, false)
            .then(async (response) => {
                return response.join();
            })
            .catch((error) => error);
    }
);
export const getByIdBannerAction = createAsyncThunk(
    BANNER_CONST.GET_BY_ID,
    async (id) => {
        return Get(BANNER_API.GET_ITEM_API, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);
export const updateBannerAction = createAsyncThunk(
    BANNER_CONST.UPDATE,
    async (model) => {
        return Put(BANNER_API.UPDATE_BANNER_API, model.id, model.data)
            .then(async (response) => {
                return response.json();
            })
            .catch((error) => error);
    }
);
export const deleteBannerAction = createAsyncThunk(
    BANNER_CONST.DELETE,
    async (id) => {
        return DeleteSingle(BANNER_API.DELETE_BANNER_API, id, null)
            .then(async (response) => {
                return response.status;
            })
            .catch((error) => error);
    }
);
const initialState = {
    listBanner: [],
    totalBanner: 0,
    itemBanner: null
};
export const bannerSlice = createSlice({
    name: BANNER_CONST.NAME_SLICE,
    initialState,
    reducers: {
        // filter lúc xóa bản ghi thành công
        filterListBanner: (state, action) => {
            state.listBanner =
                state.listBanner.length > 0
                    ? state.listBanner.filter((x) => x.id !== action.payload)
                    : [];
            state.totalBanner -= 1;
        },
        // đặt lại itemBanner sau mỗi lần thoát khỏi màn hình edit
        setCurrentItemBanner: (state) => {
            state.itemBanner = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bannerPagingAction.fulfilled, (state, action) => {
                state.listBanner = action.payload.data
                    ? action.payload.data.$values
                    : [];
                state.totalBanner = action.payload.totalRecords;
            })
            .addCase(getByIdBannerAction.fulfilled, (state, action) => {
                state.itemBanner = action.payload;
            });
    }
});
export const {filterListBanner, setCurrentItemBanner} = bannerSlice.actions;

export default bannerSlice.reducer;
